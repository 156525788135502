import React from 'react'
import { createBreakpoint } from 'react-use'
import { Box } from 'grommet'
import { Card, ContainerHeader } from '../index'

const useBreakpoint = createBreakpoint({ small: 0, medium: 769, large: 1200 })

const ContainerList = (props) => {
  const { section, header, color, fontSize, condensed, basis, items, children, ...rest } = props
  const breakpoint = useBreakpoint()

  const dynamicBasis = () => {
    switch(breakpoint) {
      case "small":
        return "full"
      case "medium":
        return "1/2"
      default:
        return basis
    }
  }

  return (
    <>
      <ContainerHeader 
        title={header.title} 
        subTitle={header.subTitle} 
        align={header.align} 
        size={header.size}
      />
      <Box 
        direction="row-responsive"
        wrap={true}
        justify="between"
        pad={{ horizontal: "10vw", top: "large" }}
        margin={{ top: "large" }}
        {...rest}
      >
      {
        items && items.map((item, index) => 
          <Card 
            key={index}
            className={`item-${index}`}
            pad={{ horizontal: "1.25vw" }}
            margin={{ bottom: "large" }}
            basis={dynamicBasis()}
            fontSize={fontSize}
            color={color}
            condensed={condensed}
            {...item}
          />
        )
      }
      </Box>
    </>
  )
}

ContainerList.defaultProps = {
  basis: "1/3",
  condensed: false,
  header: {}
}

export default ContainerList