import React from 'react'
import { Box, Heading, Text } from 'grommet'
import styled from 'styled-components'

const DocsTitle = ({ title, description }) => {
  return (
    <Box
      margin={{ left: "medium", bottom: "medium" }}
      border={{
        color: "light-6",
        size: "xsmall",
        style: "solid",
        side: "bottom"
      }}
    >
      <TitleHeading level='1' margin={{ bottom: "small" }} color="black">
        {title}
        {
          description && <SubHeading as="div">{description}</SubHeading>
        }
      </TitleHeading>
    </Box>
  )
}

const TitleHeading = styled(Heading)`
  font-weight: 500;
  font-size: 2.3em;
  margin-top: .6em;;
` 

const SubHeading = styled(Text)`
  font-size: .55em;
  font-weight: 500;
  line-height: 1.1em;
  color: ${props => props.theme.global.colors["dark-2"]}
`

export default DocsTitle