import React, { useEffect } from 'react'
import { Box } from 'grommet'
import { PageHelmet } from '../../common'
import { MessageBar } from '..'
import { useOptions } from '../../../state/hooks' 

const DefaultLayout = ({ location, title, titleTemplate, description, canonical, backgroundColor, children }) => {
  const { setLocation } = useOptions() 

  useEffect(() => {
    setLocation(location)
  }, [location])

  return (
    <Box background={backgroundColor} width={{ max: "1680px" }}>
      <PageHelmet 
        title={title} 
        titleTemplate={titleTemplate}
        description={description}
        backgroundColor={backgroundColor} 
        canonical={canonical}
        location={location}
      />
      <MessageBar />
      {children}
    </Box>
  )
}

export default DefaultLayout