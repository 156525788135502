import React from 'react'
import styled, { keyframes } from 'styled-components'

const Wave = ({ backgroundColor, style }) => {
  return (
    <Container>
      <BackgroundImage xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1205 918" style={style}>
        <path 
          fill={backgroundColor || "rgb(76, 95, 232, 0.05)"}
          fillRule="evenodd"
          opacity="1"
          d="M786.762 81.215c-5.731 0-11.435.12-17.11.348-106.873 4.14-114.975-2.673-172.065-38.455C555.367 15.836 505.072 0 451.077 0 325.84 0 220.502 85.158 189.724 200.742c-19.29 66.98-24.02 74.033-83.435 121.661l.01.005C41.498 374.42 0 454.288 0 543.865 0 700.6 127.012 827.66 283.69 827.66c26.16 0 51.486-3.559 75.539-10.19 81.736-20.874 101.626-24.156 209.346 39.146C632.095 895.55 706.808 918 786.762 918 1017.749 918 1205 730.68 1205 499.607c0-231.072-187.251-418.392-418.238-418.392" 
        />
      </BackgroundImage>
    </Container>
  )
}

const animation = keyframes`
  0% {
		transform: rotate(0deg) scale(1);
	}
	10% {
		transform: rotate(8deg) scale(1.05);
	}
	20% {
		transform: rotate(16deg) scale(1.1);
	}
	30% {
		transform: rotate(24deg) scale(1.1);
	}
	40% {
		transform: rotate(16deg) scale(1.15);
	}
	50% {
		transform: rotate(24deg) scale(1.2);
	}
	60% {
		transform: rotate(32deg) scale(1.15);
	}
	70% {
		transform: rotate(24deg) scale(1);
	}
	80% {
		transform: rotate(16deg) scale(1.1);
	}
	90% {
		transform: rotate(8deg) scale(1.1);
	}
	100% {
		transform: rotate(0deg) scale(1);
	}
`

const Container = styled.div`
  position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: -2;
	overflow: visible;
`

const BackgroundImage = styled.svg`
  position: absolute;
  top: 10vh;
  left: -40vw;
  width: calc(90vw + 25vh);
  height: auto;
  transform-origin: center;
  will-change: transform;
  animation-delay: 2s;
  animation: ${animation} 60s cubic-bezier(0.4, 0, 0.2, 1) infinite;
`

export default Wave