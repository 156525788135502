import React from 'react'
import { Box } from 'grommet'
import { tagger } from '../../../utils'
import { ConnectorLayout, ConnectorList } from '../..'

const ConnectorIndex = ({ title, titleTemplate, description, pageHero, location, data, tags }) => {
  const connectors = data.connectors
  const destinations = data.destinations
  const bi = data.bi
  const connectorTags = tags && tagger(tags.map(i => i.title), `/connectors/type/`)

  return (
    <ConnectorLayout 
      location={location} 
      title={title}
      description={description}
      titleTemplate={titleTemplate}
      pageHero={pageHero}
      tags={connectorTags}
    >
      <Box fill="horizontal">
        <ConnectorList
          title="Source Connectors"
          description="Easily synchronize your data into a centralized data warehouse without managing complex ETL jobs."
          connectors={connectors}
        />
        <ConnectorList
          title="Destination Connectors"
          description="Centralize your data. Improve data governance and business analytics."
          connectors={destinations}
          pathPrefix={[".", "warehouse"]}
        />
        <ConnectorList
          title="Business Reporting Your Way"
          description="Use existing tools your teams already use and love."
          connectors={bi}
          pathPrefix={[".", "bi-tools"]}
        />
      </Box>
    </ConnectorLayout>
  )
}

ConnectorIndex.defaultProps = {
  titleTemplate: "Hippobyte"
}

export default ConnectorIndex