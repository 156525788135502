import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Box, Grommet } from 'grommet'
import { Background, Footer, Header, Hero } from '../../../components'
import { useOptions } from '../../../state/hooks' 
import { theme } from '../../../style'
import { Breadcrumb, DefaultLayout, Main, PageHeading } from '../index'

const PageLayout = ({ align, title, titleTemplate, description, canonical, location, header, footer, breadcrumbs, pageHeading, pageHero, pageTheme, backgroundColor, backgroundImages, children }) => {
  const { setLocation, setPageBackground } = useOptions() 

  useEffect(() => {
    setLocation(location)
  }, [location])

  useEffect(() => {
    setPageBackground(backgroundColor)
  }, [backgroundColor])

  return (
    <Container theme={theme} full>
      <DefaultLayout 
        location={location} 
        title={title} 
        titleTemplate={titleTemplate}
        description={description}
        backgroundColor={backgroundColor}
        canonical={canonical}
      >
        <ContentContainer style={{ position: "relative", overflow: "hidden" }}>
          <Background backgroundImages={backgroundImages} />
          {
            header && <Header pageTheme={pageTheme} {...header} />
          }
          <Main>
            {
              breadcrumbs && <Breadcrumb {...breadcrumbs} />
            }
            {
              pageHeading && (
                <PageHeading 
                  align={align} 
                  pageTheme={pageTheme} 
                  background={backgroundColor} 
                  {...pageHeading} 
                />
              )
            }
            {
              pageHero && <Hero {...pageHero} />
            }
            <Box background={backgroundColor}>
              {children}
            </Box>
          </Main>
          {
            footer && <Footer {...footer} />
          }
        </ContentContainer>
      </DefaultLayout>
    </Container>
  )
}

PageLayout.defaultProps = {
  header: {},
  footer: {},
  pageTheme: "white",
  titleTemplate: "Hippobyte"
}

const Container = styled(Grommet)`
  @media print {
    overflow: unset !important;
    width: auto;
    height: auto;
  }
`

const ContentContainer = styled(Box)`
  @media print {
    color: ${props => props.theme.global.colors["dark-2"]};
    font-weight: 600;
    width: auto;
    height: auto;
    overflow: unset !important;
    padding-right: 2.25em;
    padding-left: 2.25em;

    div {
      margin-left: unset;
      margin-right: unset;
    }

    a { 
      color: black !important;
    }
    
    a::after {
      color: ${props => props.theme.global.colors["dark-4"]};
      content: " (" attr(href) ")";
      font-weight: 400;
    }
  }
`

export default PageLayout