import React from 'react'
import { AnchorLink, ConnectorLayout } from '../..'
import Img from 'gatsby-image'
import { tagger, slugger } from '../../../utils'
import { Box, Heading } from 'grommet'
import { FormPreviousLink, Integration } from 'grommet-icons'
import { Highlight } from '../..'

const ConnectorSourceDestination = ({ location, pageContext, titleTemplate, description }) => {
  const { connector, destination } = pageContext
  const tags = pageContext.tags && tagger(pageContext.tags.map(i => i.title), `/connectors/type/`)

  const connectorImage = connector.featuredimage && connector.featuredimage.childImageSharp && connector.featuredimage.childImageSharp.fluid
  const destinationImage = destination.featuredimage && destination.featuredimage.childImageSharp && destination.featuredimage.childImageSharp.fluid
  const defaultDescription = `The ${connector.title} ${connector.category.toLowerCase()} helps with ${connector.product.tags.join(", and ")} management. The Hippobyte ${connector.title} connector replicates and synchronizes data with ${destination.title} without any maintance on your part.`

  const randomElement = (array) => array[Math.floor(Math.random() * array.length)]

  const destinationHeadings = [
    `Deliver ${connector.title} data to ${destination.title} -- automatically.`,
    `Reliably replicate ${connector.title} data to ${destination.title}.`,
    `${connector.title} data to ${destination.title} -- Fully managed ETL.`
  ]

  const isDestination = destination.sourceDestination === "destination"

  const pageTitle = isDestination ? `${connector.title} to ${destination.title} Automated ETL` : `${connector.title} and ${destination.title}`
  const pageHeading = isDestination ? randomElement(destinationHeadings) : `Analyze ${connector.title} data with ${destination.title}`
  const pageSubHeading = isDestination ? `${connector.connectorDescription ||  defaultDescription}`  : `Hippobyte provides you unfettered access to your ${connector.title} data so you can use all features offered by ${destination.title}.`
  
  const highlightContent = () => {
    const content = [
      { 
        title: "Source Connector", 
        description: `${connector.title}`,
        markdown: `
          <div style="background: #F6F7FB; border: 1px solid #e8e9ef; border-radius: 8px">
            <div style="border-bottom: 1px solid #e8e9ef; padding: 12px">
              <strong style="display: inline-block; width: 170px">Connector Name:</strong> ${connector.title}<br />
            </div>
            <div style="padding: 12px">
              <strong style="display: inline-block; width: 170px">Connector Type:</strong> ${connector.category} (${connector.sourceDestination})
            </div>
          </div>
          <div style="margin: 24px 12px 0">
            <strong>Connector Overview:</strong>
            <p>${connector.product.connectorDescription}</p>
          </div>
          <div style="margin: 24px 12px 0">
            <strong>${connector.category} Overview:</strong>
            <p>${connector.product.description}</p>
          </div>`
      },
      { 
        title: isDestination ? `Destination Connector` : `Business Intelligence`, 
        description: `${destination.title}`,
        markdown: `
          <div style="background: #F6F7FB; border: 1px solid #e8e9ef; border-radius: 8px">
            <div style="border-bottom: 1px solid #e8e9ef; padding: 12px">
              <strong style="display: inline-block; width: 170px">Connector Name:</strong> ${destination.title}<br />
            </div>
            <div style="padding: 12px">
              <strong style="display: inline-block; width: 170px">Connector Type:</strong> ${destination.category} ${isDestination ? `(${destination.sourceDestination})` : ``}
            </div>
          </div>
          <div style="margin: 24px 12px 0">
            <strong>${isDestination ? `Connector Overview` : `Overview`}:</strong>
            <p>${destination.product.connectorDescription}</p>
          </div>
          <div style="margin: 24px 12px 0">
            <strong>${destination.category} Overview:</strong>
            <p>${destination.product.description}</p>
          </div>`
      }
    ]
  
    const docs = ({ 
      title: "Prerequisites and Documentation", 
      description: `${connector.category} data connector`,
      markdown: `
        <p>Setting up the integration between <strong>${connector.title}</strong> and <strong>${destination.title}</strong> requires just a few minutes. Hippobyte replicates and synchronizes your ${connector.title} data into ${destination.title} to allow analysts utilize their favorite business intelligence tools to build multi-source dashboards and reports.</p>
        <p>Hippobyte is a fully managed platform. You won't need a team of engineers or support resources to build better analytics solutions utilizing your ${connector.title} data. All your ${connector.title} data will be reliably replicated to ${destination.title} into tidy tables so you can use the power of SQL and your existing analytic tools.</p>
        <p>See the <a href="/">${connector.title} documentation</a> for additional information.</p>
      `
    })
  
    if (isDestination) { 
      content.push(docs)
    }
  
    return content
  }

  return (
    <ConnectorLayout 
      location={location} 
      tags={tags}
      titleTemplate={titleTemplate}
      title={pageTitle}
      canonical={slugger(["connectors", connector.title], "/", location.origin)}
      pageHero={{
        children: (
          <Box>
            <Heading
              level={1}
              margin="0"
              color="black"
              textAlign="center"
              alignSelf="center"
            >
              {pageHeading}
            </Heading>
            <Box width="horizontal" align="center">
              <Box width="700px" direction="row-responsive" margin={{ vertical: "xlarge" }}>
                <Box basis="1/3" align="center" justify="center">
                  <Box border={{ color: "brand-3", size: "small" }} round="small" pad="xsmall">
                    <Box border={{ color: "brand-5", size: "xsmall" }} pad="small" round="small" width="small" height="small" justify="center">
                      <Img 
                        fluid={connectorImage} 
                        imgStyle={{ objectFit: "contain" }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box basis="1/3" align="center" justify="center">
                  <Box pad="small" >
                    <Integration color="brand" size="large" /> 
                  </Box>
                </Box>
                <Box basis="1/3" align="center" justify="center">
                  <Box border={{ color: "brand-3", size: "small" }} round="small" pad="xsmall">
                    <Box border={{ color: "brand-5", size: "xsmall" }} pad="small" round="small" width="small" height="small" justify="center">
                      <Img 
                        fluid={destinationImage} 
                        imgStyle={{ objectFit: "contain" }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Heading
              level={2}
              margin={{ top: "0", bottom: "medium" }}
              color="dark-5"
              textAlign="center"
              alignSelf="center"
              size="small"
            >
              {pageSubHeading}
            </Heading>
          </Box>
        )
      }}
    >
      <Box fill="horizontal" margin={{ horizontal: "11vw" }}>
        <AnchorLink 
          label={`Back to ${connector.title} Connector`}
          path="../" 
          icon={<FormPreviousLink />} 
        />
        <Highlight 
          margin={{ horizontal: 0 }}
          height="auto"
          title="Getting Started"
          description={`Quick, easy, reliable ETL for ${connector.title}.`}
          actions={highlightContent()}
        />
      </Box>
    </ConnectorLayout>
  )
}

ConnectorSourceDestination.defaultProps = {
  titleTemplate: "Hippobyte"
}

export default ConnectorSourceDestination