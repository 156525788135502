import * as React from "react"

function SvgComponent({ style, color, randomizeColors }) {
  const colors = {
    bright: [
      "darkblue", "tomato", "powderblue", "royalblue", "yellowgreen", "dodgerblue", "cornflowerblue", "mediumvioletred", "deepskyblue", "midnightblue", "steelblue", "lightgreen", "darkslateblue"
    ],
    grays: [
      "#f9fcff", "#f9f9ff", "#fcf8ff", "#fff9fc", "#fff9f9", "#f9fffb", "#f9faff"
    ]
  }

  const randomColor = (type) => {
    if (randomizeColors) {
      if (type === "grays") {
        return colors.grays[Math.floor(Math.random() * colors.grays.length)]
      } else {
        return colors.bright[Math.floor(Math.random() * colors.bright.length)]
      }
    } else {
      return (color || "whitesmoke")
    }
  }

  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1550" height="480" viewBox="0 0 1550 480">
      <g id="custom_1" dataName="custom – 1" clip-path="url(#clip-custom_1)">
        <rect id="Rectangle_1" dataName="Rectangle 1" width="805" height="15" rx="7.5" transform="translate(750)" fill={randomColor("grays")} />
        <rect id="Rectangle_2" dataName="Rectangle 2" width="805" height="15" rx="7.5" transform="translate(800 50)" fill={randomColor("grays")} />
        <rect id="Rectangle_3" dataName="Rectangle 3" width="805" height="15" rx="7.5" transform="translate(850 100)" fill={randomColor("grays")} />
        <rect id="Rectangle_4" dataName="Rectangle 4" width="805" height="15" rx="7.5" transform="translate(900 150)" fill={randomColor("grays")} />
        <rect id="Rectangle_5" dataName="Rectangle 5" width="805" height="15" rx="7.5" transform="translate(950 200)" fill={randomColor("grays")} />
        <rect id="Rectangle_6" dataName="Rectangle 6" width="805" height="15" rx="7.5" transform="translate(1000 250)" fill={randomColor("grays")} />
        <rect id="Rectangle_7" dataName="Rectangle 7" width="805" height="15" rx="7.5" transform="translate(1050 300)" fill={randomColor("grays")} />
        <rect id="Rectangle_8" dataName="Rectangle 8" width="805" height="15" rx="7.5" transform="translate(1100 350)" fill={randomColor("grays")} />
        <rect id="Rectangle_9" dataName="Rectangle 9" width="805" height="15" rx="7.5" transform="translate(1150 400)" fill={randomColor("grays")} />
        <rect id="Rectangle_10" dataName="Rectangle 10" width="805" height="15" rx="7.5" transform="translate(1200 450)" fill={randomColor("grays")} />
        <circle id="Ellipse_3" dataName="Ellipse 3" cx="7.5" cy="7.5" r="7.5" transform="translate(755 50)" fill={randomColor()}/>
        <circle id="Ellipse_4" dataName="Ellipse 4" cx="7.5" cy="7.5" r="7.5" transform="translate(710 50)" fill={randomColor()}/>
        <circle id="Ellipse_5" dataName="Ellipse 5" cx="7.5" cy="7.5" r="7.5" transform="translate(665 50)" fill={randomColor()}/>
        <circle id="Ellipse_6" dataName="Ellipse 6" cx="7.5" cy="7.5" r="7.5" transform="translate(820 100)" fill={randomColor()}/>
        <circle id="Ellipse_7" dataName="Ellipse 7" cx="7.5" cy="7.5" r="7.5" transform="translate(735 100)" fill={randomColor()}/>
        <circle id="Ellipse_8" dataName="Ellipse 8" cx="7.5" cy="7.5" r="7.5" transform="translate(860 150)" fill={randomColor()}/>
        <circle id="Ellipse_11" dataName="Ellipse 11" cx="7.5" cy="7.5" r="7.5" transform="translate(955 250)" fill={randomColor()}/>
        <circle id="Ellipse_12" dataName="Ellipse 12" cx="7.5" cy="7.5" r="7.5" transform="translate(1070 350)" fill={randomColor()}/>
        <circle id="Ellipse_13" dataName="Ellipse 13" cx="7.5" cy="7.5" r="7.5" transform="translate(1040 350)" fill={randomColor()}/>
        <circle id="Ellipse_14" dataName="Ellipse 14" cx="7.5" cy="7.5" r="7.5" transform="translate(1120 400)" fill={randomColor()}/>
        <circle id="Ellipse_15" dataName="Ellipse 15" cx="7.5" cy="7.5" r="7.5" transform="translate(1170 450)" fill={randomColor()}/>
        <circle id="Ellipse_16" dataName="Ellipse 16" cx="7.5" cy="7.5" r="7.5" transform="translate(710)" fill={randomColor()}/>
        <circle id="Ellipse_17" dataName="Ellipse 17" cx="7.5" cy="7.5" r="7.5" transform="translate(665)" fill={randomColor()}/>
        <circle id="Ellipse_18" dataName="Ellipse 18" cx="7.5" cy="7.5" r="7.5" transform="translate(620)" fill={randomColor()}/>
      </g>
    </svg>
  )
}

export default SvgComponent
