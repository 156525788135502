import React from 'react'
import styled, { css } from 'styled-components'
import { Button } from 'grommet'

const Submit = (props) => {
  return (
    <Container 
      type="submit"
      {...props}
    />
  )
}

Submit.defaultProps = {
  label: "Submit"
}

const Container = styled(Button)`
  font-weight: 600;
  letter-spacing: 1px;
  color: white;
  background: ${props => props.theme.global.colors.brand};
`

export default Submit