import React from 'react'
import { Box, Heading, Text } from 'grommet'
import { Image } from '../../../components'

const Card = (props) => {
  const { title, description, color, fontSize, theme, condensed, image, path, elevation, className, onClick, children, ...rest } = props

  const horizontalPositions = ["left", "right"]

  const imageSizer = () => {
    switch(image.size) {
      case "large":
        return "80px"
      case "medium":
        return "70px"
      case "small":
        return "50px"
      default:
        return image.size || "70px"
    }
  }
  
  const Title = (title) => {
    const { children } = title

    const fontSizer = () => {
      switch(fontSize) {
        case "large":
          return "1.35em"
        case "medium":
          return "1.20em"
        case "small":
          return "0.95em"
        default:
          return fontSize || "1.0em"
      }
    }

    const marginSizer = () => {
      switch(fontSize) {
        case "large":
          return condensed ? "xsmall" : "small"
        case "medium":
          return condensed ? "0" : "xsmall"
        case "small":
          return "0"
        default:
          return "0"
      }
    }

    const fontColor = () => {
      if (color) { return color }

      switch(theme) {
        case "black":
          return "black"
        case "white":
          return "white"
      }
    }

    return (
      <Heading 
        level={3}
        color={fontColor()}
        margin={{ top: "none", bottom: marginSizer() }}
        style={{fontSize: fontSizer(), fontWeight: "900"}}
      >
        {children}
      </Heading>
    )
  }

  const Description = (description) => {
    const { children } = description

    const fontSizer = () => {
      switch(fontSize) {
        case "large":
          return "1.20em"
        case "medium":
          return "1.15em"
        case "small":
          return "1.05em"
        default:
          return fontSize || "1.06em"
      }
    }

    const fontColor = () => {
      if (color) { return color }

      switch(theme) {
        case "black":
          return "dark-5"
        case "white":
          return "light-4"
      }
    }

    return (
      <Text 
        color={fontColor()}
        weight={400}
        style={{ opacity: "0.9", fontSize: fontSizer(), lineHeight: condensed ? "1.2em" : "1.7em" }}
      >
        {children}
      </Text>
    )
  }

  return (
    <Box 
      style={{position: "relative", transition: "all .3s"}}
      {...rest}
    >
      {
        image && image.position === "top" && <Image src={image.src} height={imageSizer()} theme={image.theme} style={{marginBottom: "18px"}} />
      }
      <Box direction={image.position === "left" ? "row-reverse" : "row"}>
        <Box>
          { 
            title && <Title>{title}</Title>
          }
          { 
            description && <Description>{description}</Description>
          }
          {children}
        </Box>
        {
          image && horizontalPositions.includes(image.position) && (
            <Box flex="grow" justify={image.justify || "center"} align="end" margin={image.position === "left" ? { right: "medium" } : { left: "medium"}}>
              <Image src={image.src} height={imageSizer()} theme={image.theme} />
            </Box>
          )
        }
      </Box>
      {
        image && image.position === "bottom" && <Image src={image.src} height={imageSizer()} theme={image.theme} style={{marginTop: "18px"}} />
      }
    </Box>
  )
}

Card.defaultProps = {
  fontSize: "default",
  theme: "black"
}

export default Card