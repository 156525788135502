import React from 'react'
import Img from 'gatsby-image'
import { Box, Heading, Paragraph, Text } from 'grommet'
import { ContainerHeader } from '../index' 
import { AnchorLink, DataFlow } from '../../../components' 
import { colorWheel } from '../../../utils'

const Feature = ({ themeColor, themeOffset, title, subTitle, aside, image, dataFlow, reverse }) => {
  return (
    <Box>
      <ContainerHeader title={title} subTitle={subTitle} />
      <Box 
        height="586px" 
        background={themeColor} 
        round="xsmall" 
        margin={{ vertical: "xlarge", horizontal: "6.5vw" }}
        style={{ position: "relative" }}
      >
        <MainImage
          reverse={reverse}
          {...image}
        />
        <Aside
          reverse={reverse}
          themeColor={themeColor}
          themeOffset={themeOffset}
          dataFlow={dataFlow}
          {...aside}
        />
      </Box>
    </Box>
  )
}

const MainImage = ({ type, content, reverse, style, ...rest }) => {
  const defaultStyle = { 
    position: "absolute", 
    bottom: 0
  }

  const css = { ...defaultStyle, ...style }

  return (
    <Box style={css} {...rest}>
      { type === "fluid" &&  <Img fluid={content} /> }
    </Box>
  )
}

const Aside = ({ themeColor, themeOffset, header, title, description, actionLabel, actionPath, reverse, dataFlow }) => {
  const defaultStyle = { 
    position: "absolute", 
    zIndex: 2, 
    top: "-40px", 
    overflow: "hidden"
  }

  const css = reverse ? { 
    left: "8%", ...defaultStyle 
  } : { 
    right: "8%", ...defaultStyle 
  }

  return (
    <Box 
      round="xsmall" 
      background="white" 
      width="484px"
      pad="10px"
      style={css}
    >
      <Box 
        round="xsmall" 
        pad={{ horizontal: "3em", top: "2.5em", bottom: "2em" }}
        height="520px"
        justify="between"
        style={{ boxShadow: `0 0 0 1px ${colorWheel(themeColor, themeOffset)}` }}
      >
        <Box height={dataFlow ? "430px" : "490px"}>
          <Text 
            weight={900} 
            size="small" 
            color="dark-1" 
            margin={{ bottom: "small" }}
            style={{ textTransform: "uppercase", letterSpacing: "0.6px" }}
          >
            {header}
          </Text>
          <Heading 
            level={2}
            color="black" 
            margin={{ top: "small", bottom: "medium" }}
            style={{ 
              fontWeight: 400, 
              fontFamily: "'Barlow Semi Condensed', sans-serif",
              lineHeight: "1.35em"
            }}
          >
            {title}
          </Heading>
          <Paragraph 
            weight={500} 
            color="dark-4" 
            style={{ margin: "0", lineHeight: "2em" }}
          >
            {description}
          </Paragraph>
        </Box>
        {
          actionLabel && actionPath && (
            <AnchorLink label={<Text weight="700" margin={{ top: "small" }}>{actionLabel}</Text>} path={actionPath} />
          )
        }
        {
          dataFlow && (
            <Box height="60px" justify="center">
              <DataFlow {...dataFlow} />
            </Box>
          )
        }
      </Box>
    </Box>
  )
}

Aside.defaultProps = {
  themeOffset: -40
}

export default Feature