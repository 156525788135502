import React from 'react'
import { Grommet, Grid } from 'grommet'
import { DefaultLayout } from '../index'
import { theme } from '../../../style'

const DocsLayout = (props) => {
  const { location, title, titleTemplate, description, children } = props
  
  return (
    <Grommet theme={theme}>
      <DefaultLayout 
        location={location} 
        title={title}
        titleTemplate={titleTemplate || "Hippobyte Docs"}
        description={description}
      >
        <Grid
          id="docs-layout"
          rows={["auto", "flex"]}
          columns={["auto", "flex"]}
          areas={[
            { name: "sidebar", start: [0, 1], end: [0, 1] },
            { name: "main", start: [1, 1], end: [1, 1] }
          ]}
        >
          {children}
        </Grid>
      </DefaultLayout>
    </Grommet>
  )
}

export default DocsLayout