import React from 'react'
import styled from 'styled-components'
import { Box, Text } from 'grommet'
import { Logo } from '../../../components'

const Copyright = ({ screenSize, marginBottom, color, size, weight, noticeOnly, ...rest }) => { 
  return (
    <Container
      direction={screenSize === "small" ? "column" : "row"}
      background="accent-1"
      pad={{ 
        horizontal: screenSize === "small" ? "11vw" : "large",
        vertical: screenSize === "small" ? "medium" : "0",
        bottom: screenSize === "small" ? "large" : marginBottom
      }}
      align={screenSize === "small" ? "start" : "center"}
      {...rest}
    >
      {
        noticeOnly ? (
          <Notice color={color} size={size} weight={weight} />
        ) : (
          <>
          <Box basis="1/2" align="start">
            <Logo 
              logoStyle={{ fill: "#EFF0F1", width: "18px" }}
              style={{ color: "#EFF0F1", fontSize: "12px" }} 
            />
          </Box>
          <Box basis="1/2" align="end">
            <Notice />
          </Box>
          </>
        )
      }
    </Container>
  )
}

const Notice = ({ color, size, weight }) => {
  const from = 2015
  const to = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date())
  return (
    <CopyrightNotice 
      color={color}
      size={size}
      weight={weight}
    >
      {`\u00A9`} {from} - {to} Hippobyte, Inc. All rights reserved.
    </CopyrightNotice>
  )
}

Notice.defaultProps = {
  color: "light-1",
  size: "13px",
  weight: 500
}

const Container = styled(Box)`
  transition: all 1s;

  @media print {
    background: none;
    padding: 0;
    width: 340px;
    overflow: hidden;
    position: fixed;
    bottom: 285px;
    left: 14px;
    transform-origin: 0 0;
    transform: rotate(90deg);
    page-break-inside: avoid;

    div {
      display: none;
    }
  }
`

const CopyrightNotice = styled(Text)`
  letterSpacing: 0.65px;

  @media print {
    color: ${props => props.theme.global.colors["light-4"]};
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    margin: 0;
  }
`

export default Copyright