import React, { useEffect } from 'react'
import { Box, Grommet, Heading } from 'grommet'
import { useOptions } from '../../../state/hooks' 
import { theme } from '../../../style'
import { Logo, Hero, Tags } from '../../../components'
import { DefaultLayout, Footer, Header, Main } from '../index'

const BlogLayout = ({ title, description, canonical, location, tags, pageTheme, showHeading, topic, footer, children }) => {
  const { setLocation } = useOptions() 
  const { pathname } = location

  useEffect(() => {
    setLocation(location)
  }, [location])

  return (
    <Grommet theme={theme} full>
      <DefaultLayout 
        location={location} 
        title={title}
        description={description}
        titleTemplate="Hippobyte"
        canonical={canonical}
      >
        <Box style={{ minHeight: "100vh" }}>
          <Header 
            border={{
              color: "light-3",
              size: "xsmall",
              style: "solid",
              side: "bottom"
            }}
            pad={{
              vertical: "small",
              horizontal: "11vw",
            }}
            pageTheme={pageTheme}
          />
          <Main margin={{ top: "-12px" }}>
            <Box
              direction="row"
              align="center"
              border={{
                color: "light-3",
                size: "xsmall",
                style: "solid",
                side: "bottom"
              }}
              pad={{
                vertical: "small",
                horizontal: "11vw"
              }}
              background="#ffffffed"
              style={{
                position: "sticky",
                top: "0",
                zIndex: 1
              }}
            >
              <Box>
                <Logo 
                  showLogo={false}
                  companyName={null}
                  logoLabel="Topics"
                />
              </Box>
              <Tags 
                tags={tags} 
                label="All articles"
                path="/articles"
                pathname={pathname} 
              />
            </Box>
            {
              showHeading && (
                <Hero>
                  <Box fill="horizontal" margin={{ vertical: "0", horizontal: "11vw" }}>
                    <Heading
                      level={1}
                      margin="0"
                      color="black"
                    >
                      {topic || "The Hippobyte Blog"}
                    </Heading>
                    <Heading
                      level={2}
                      margin={{ top: "small", bottom: "0" }}
                      color="dark-5"
                      size="small"
                      style={{ minWidth: "80%"}}
                    >
                      {
                        topic ? (
                          `Articles and blog entries tagged '${topic}'`
                        ) : (
                          `Advice, research, how-tos, and insights — all to help you level-up your business analytics and data integration skills.`
                        )
                      }
                    </Heading>
                  </Box>
                </Hero>
              )
            }
            <Box
              align="center"
              margin={{ horizontal: "11vw" }}
            >
              {children}
            </Box>
          </Main>
          {
            footer && (
              <Footer 
                background="light-0" 
                margin="0" 
                pad={{ horizontal: "0", vertical: "large" }}
                align="center"
                style={{ textAlign: "center" }}
                {...footer} 
              />
            )
          }
        </Box>
      </DefaultLayout>
    </Grommet>
  )
}

BlogLayout.defaultProps = {
  header: {},
  footer: {},
  tags: [],
  pageTheme: "dark",
  showHeading: true
}

export default BlogLayout