import React from 'react'
import styled, { css } from 'styled-components'
import { Button } from 'grommet'
import { navigate } from 'gatsby'

const AnchorButton = ({ path, onClick, ...rest }) => {
  const handleClick = (event) => {
    onClick && onClick(event)
    path && navigate(path)
  }
  
  return (
    <Container 
      onClick={handleClick}
      {...rest} 
    />
  )
}

const Container = styled(Button)`
  ${props => props.color === "white" && css`
    background-color: transparent;
    color: white;
  `}

  ${props => props.primary && css`
    color: ${props => props.theme.global.colors.white};
    font-weight: 900;
    letter-spacing: 0.6px;
    background: ${props => props.theme.global.colors.brand};
    
    ${props.size === "small" && css`
      padding: 6px 18px;
    `}
  `}

  ${props => props.weight && css`
    font-weight: ${props => props.weight};
  `}

  ${props => props.href && css`
    text-align: center;
  `}

  ${props => props.outline && css`
    color: ${props => props.theme.global.colors.brand};
    font-weight: 700;
    letter-spacing: 0.6px;
    
    ${props.size === "small" && css`
      padding: 6px 18px;
    `}

    ${props.color === "white" && css`
      color: white;
    `}

    &:hover {
      box-shadow: 0 0 3px 0;
    }
  `}
`

export default AnchorButton