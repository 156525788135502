import React from 'react'
import { createBreakpoint } from 'react-use'
import { Box } from 'grommet'
import { AnchorButton, Section } from '../../../components'
import { PageTitle, PageSubTitle } from '../../../components/layout'
import { useOptions } from '../../../state/hooks' 
import { Quote } from '../index'

const useBreakpoint = createBreakpoint({ small: 0, medium: 769, large: 1200 })

const Hero = ({ section, main, aside, quote, children, ...rest }) => {
  const { options } = useOptions()
  const breakpoint = useBreakpoint()

  return (
    <Section margin={{ bottom: "0" }} background={options.pageBackground} {...section}>
      <Box direction={breakpoint === "small" ? "column" : "row"} gap="xlarge" align="start" justify="center" pad={{ top: "xlarge", bottom: "large" }} {...rest}>
        { main && <Main quote={quote} breakpoint={breakpoint} {...main} /> }
        { aside && <Aside breakpoint={breakpoint} {...aside} /> }
        {children}
      </Box>
    </Section>
  )
}


const Main = ({ basis, heading, subHeading, button, content, quote, breakpoint, ...rest }) => {
  return (
    <Box basis={basis} {...rest}>
      {
        heading && (
          <PageTitle 
            label={heading.label}
            margin="0"
            breakpoint={breakpoint}
            {...heading}
          />
        )
      }
      {
        subHeading && (
          <PageSubTitle 
            label={subHeading.label}
            breakpoint={breakpoint}
            {...subHeading}
          />
        )
      }
      {
        button && (
          <AnchorButton 
            primary
            margin={{ top: "medium" }}
            alignSelf="start"
            size="large"
            {...button} 
          />
        )
      }
      {
        quote && <Quote {...quote} />
      }
      {
        content && content
      }
    </Box>
  )
}

const Aside = (props) => {
  const { basis, content, ...rest } = props

  return (
    <Box basis={basis} {...rest}>
      {
        content && content
      }
    </Box>
  )
}

Main.defaultProps = {
  basis: "1/2",
  button: null
}

Aside.defaultProps = {
  basis: "1/2"
}

export default Hero