import React from 'react'
import { Box } from 'grommet'
import { AnchorButton } from '../../../components'
import { PageTitle, PageSubTitle } from '../index'

const PageHeading = ({ pageTheme, background, align, title, subTitle, actionButton, ...rest }) => {
  if (title) {
    return (
      <Box 
        pad={align === "center" ? { horizontal: "24%" } : { horizontal: "11vw" }} 
        align={align}
        background={pageTheme === "light" && background} 
        height={align === "center" && "92vh"}
        {...rest}
      >
        <PageTitle 
          align={align}
          pageTheme={pageTheme} 
          {...title} 
        />
        <PageSubTitle 
          align={align}
          pageTheme={pageTheme} 
          {...subTitle} 
        />
        <ActionButton 
          align={align}
          pageTheme={pageTheme} 
          {...actionButton} 
        />
      </Box>
    )
  }
  return null
}

PageHeading.defaultProps = {
  align: "start"
}

const ActionButton = (props) => {
  if (props.label) {
    return (
      <Box>
        <AnchorButton 
          primary
          alignSelf="center"
          size="medium"
          primary={true}
          {...props} 
        />
      </Box>
    )
  } else {
    return null
  }
}

export default PageHeading