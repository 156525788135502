import React from 'react'
import { Box, Heading, List, Text } from 'grommet'
import { FormNext } from 'grommet-icons'
import { AnchorLink } from '../../../components'
import { slugger } from '../../../utils'

const LegalMenu = ({ data, title, ...rest }) => {

  const listItems = () => {
    return (
      data && data.map(item => {
        const label = item.post.frontmatter.title
        const path = `${slugger([label])}`
        return ({
          name: <AnchorLink label={label} path={path} />, 
          icon: <FormNext color="brand" />
        })
      })
    )
  }

  return (
    <Box margin={{ horizontal: "11vw" }} {...rest}>
      <Heading level={2} margin="0">
        <Text weight={900}>{title}</Text>
      </Heading>
      <List
        primaryKey="name"
        secondaryKey="icon"
        border={{
          side: "bottom",
          color: "light-4"
        }}
        margin={{
          bottom: "large"
        }}
        pad="xxsmall"
        data={listItems()}
      />
    </Box>
  )
}

export default LegalMenu