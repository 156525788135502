import React from 'react'
import { Box, Text } from 'grommet'

const Label = ({ label, required }) => {
  return (
    <Box as="label">
      <Text
        size="xsmall"
        weight="900"
        color="dark-5"
        margin={{
          bottom: "xxsmall"
        }}
        style={{
          textTransform: "uppercase",
          letterSpacing: "0.6px"
        }}
      >
        {label}
        {
          required && <Text color="red" size="xsmall" margin={{ left: "xxsmall" }}>*</Text>
        }
      </Text>
    </Box>
  )
}

export default Label