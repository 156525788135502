import React from 'react'
import { AnchorLink, ConnectorLayout } from '../..'
import Img from 'gatsby-image'
import { tagger } from '../../../utils'
import { Box } from 'grommet'
import { FormPreviousLink } from 'grommet-icons'
import { Highlight, ConnectorList, ConnectorBiTools, ConnectorDestinations } from '../..'

const DestinationPage = ({ location, pageContext, titleTemplate }) => {
  const { connector, connectors } = pageContext
  const { title, category, sourceDestination, featuredimage, product } = connector
  const tags = pageContext.tags && tagger(pageContext.tags.map(i => i.title), `/connectors/type/`)

  const productImage = featuredimage && featuredimage.childImageSharp && featuredimage.childImageSharp.fluid
  const description = product.connectorDescription || `Get more value from ${title} with the Hippobyte ${title} ${category} data connector. Deploy custom ${title} reports, dashboards, and actionable insights in Tableu, PowerBI, or any other BI tool without engineering experience. Hippobyte automates the complexity of data integrations so analysts can focus on delivering insights instead of wrangling ${title} data.`

  return (
    <ConnectorLayout 
      location={location} 
      tags={tags}
      titleTemplate={titleTemplate}
      title={title}
      description={description}
      pageHero={{
        pad: { top: 0, bottom: "xlarge"},
        section: { 
          margin: { vertical: "0", horizontal: "11vw" },
        },
        main: {
          heading: {
            label: `Load your ${title} data to your warehouse in minutes.`,
            margin: { bottom: "medium" },
          },
          subHeading: {
            label: description,
          },
          style: { flex: "0 1 65%" },
        },
        aside: {
          pad: { top: "xlarge" },
          content: (
            <Box height={{ min: "100px", max: "300px" }} justify="center">
              <Img 
                fluid={productImage} 
                style={{ maxHeight: "100%" }}
                imgStyle={{ objectFit: "contain" }}
              />
            </Box>
          ),
          style: { flex: "0 1 35%" },
        }
      }}
    >
      <Box fill="horizontal" margin={{ horizontal: "11vw" }}>
        <AnchorLink 
          label="Back to Connectors" 
          path="/connectors" 
          icon={<FormPreviousLink />} 
        />
        <Highlight 
          margin={{ horizontal: 0 }}
          height="auto"
          title="Getting Started"
          description={`Quick, easy, reliable ETL for ${title}.`}
          actions={[
            { 
              title: "Connector Description", 
              description: `${title}`,
              markdown: `
                <div style="background: #F6F7FB; border: 1px solid #e8e9ef; border-radius: 8px">
                  <div style="border-bottom: 1px solid #e8e9ef; padding: 12px">
                    <strong style="display: inline-block; width: 170px">Connector Name:</strong> ${title}<br />
                  </div>
                  <div style="padding: 12px">
                    <strong style="display: inline-block; width: 170px">Connector Type:</strong> ${category} (${sourceDestination})
                  </div>
                </div>
                <div style="margin: 24px 12px 0">
                  <strong>Connector Overview:</strong>
                  <p>${product.description}</p>
                </div>`
            },
            { 
              title: "Prerequisites and Documentation", 
              description: `${category} data connector`,
              markdown: `
                <p>Setting up the Hippobyte <strong>${title}</strong> data connector requires just a few minutes. Hippobyte replicates and synchronizes your ${title} data into a data warehouse or operational data store of your choice so that you can use existing business intelligence tools, and the power of SQL to explore and measure your data.</p>
                <p>Detailed documentation on how to quickly start synchronizing data can be found on the <a href="/">${title} documentation page</a>.</p>
              `
            }
          ]}
        />
        <ConnectorList
          title={`Use ${title} as a centralized trusted hub for your data`}
          description={`Implement DataOps to govern standards and data access throughout the enterprise with Hippobyte connectors.`}
          connectors={pageContext.connectors}
          pathPrefix={[".", "connectors"]}
          pathSuffix={[title]}
        />
        <ConnectorList
          title={`Connect ${title} to your existing BI platforms`}
          description={`Nothing more to buy or implement. Hippobyte makes it easy to use your existing tools.`}
          connectors={pageContext.bi}
          pathPrefix={[".", "bi-tools"]}
        />
      </Box>
    </ConnectorLayout>
  )
}

DestinationPage.defaultProps = {
  titleTemplate: "Hippobyte Data Connectors"
}

export default DestinationPage