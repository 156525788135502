import React from 'react'
import styled from 'styled-components'
import * as logos from './assets'

const ProductLogo = ({ size, name, style }) => {

  const sizer = () => {
    switch(size) {
      case "xsmall":
        return "30px"
      case "small":
        return "40px"
      case "medium":
        return "60px"
      case "large":
        return "90px"
      case "xlarge":
        return "150px"
      case "xxlarge":
        return "225px"
      default: 
        return size
    }
  }

  return (
    <Container height={sizer()}>
      {logos[name] && logos[name]({ style })}
    </Container>
  )
}

const Container = styled.div(props => ({
  svg: {
    height: props.height || "60px"
  }
}))

export default ProductLogo