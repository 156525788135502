import React from 'react'
import { Box, Text } from 'grommet'
import { DataFlow } from '../../../components'

const Quote = ({ label, name, title, companyLogo, dataFlow, size, color, ...rest }) => {
  return (
    <Box 
      border={{ 
        side: "top", 
        color: "light-6"
      }}
      margin={{
        top: "xsmall"
      }}
      pad={{
        top: "medium"
      }}
      {...rest}
    >
      {
        label && (
          <Box margin={{ bottom: "small" }}>
            <Text weight={400} size={size} color={color}>{label}</Text>
          </Box>
        )
      }
      {
        name && (
          <Text weight={700} size={size}>{name}</Text>
        )
      }
      {
        title && (
          <Text weight={500} size={size}>{title}</Text>
        )
      }
      {
        companyLogo && (
          companyLogo
        )
      }
      {
        dataFlow && (
          <DataFlow {...dataFlow} />
        )
      }
    </Box>
  )
}

Quote.defaultProps = {
  color: "dark-6",
  size: "small"
}

export default Quote