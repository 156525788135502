import React from 'react'
import styled from 'styled-components'
import { Heading } from 'grommet'

const PageTitle = ({ align, pageTheme, label, breakpoint, ...rest }) => {
  const color = pageTheme === "light" ? "white" : "dark-1"
  const fontSize = align === "center" ? "3.8em" : breakpoint
  const textAlign = align === "center" ? align : "left"
  const margin = align === "center" ? { top: "xlarge", bottom: "xxsmall" } : { top: "large", bottom: "large" }

  if (label) {
    return (
      <PageTitleHeading 
        level={1} 
        margin={margin} 
        size={fontSize} 
        color={color}
        textAlign={textAlign}
        style={{ fontWeight: 900 }}
        {...rest}
      >
        {label}
      </PageTitleHeading>
    )
  } else {
    return null
  }
}

const PageTitleHeading = styled(Heading)`
  @media print {
    margin-top: 0;
    margin-bottom: 0.2em;
    margin-left: 0;
    margin-right: 0;

    > span {
      font-size: 0.55em;
      line-height: 1em;
    }
  }
` 

export default PageTitle