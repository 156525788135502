import React from 'react'
import { Box } from 'grommet'
import { Shape } from '../index'

const ShapeContainer = ({ items, size, overflow, style, ...rest }) => {
  const overflowCss = { overflow: overflow }
  return (
    <Box 
      size={size}
      style={{ 
        position: "relative",
        ...overflowCss,
        ...style
      }}
      {...rest}
    >
    {
      items && items.map(item => <Shape {...item} size={item.size || size} />)
    }
    </Box>
  )
}

ShapeContainer.defaultProps = {
  overflow: "visible"
}

export default ShapeContainer