const menuUseCases = ({
  label: "Use Cases",
  items: [
    { 
      label: "Corporate Finance", 
      description: "Improve Engagement. Increase Conversion", 
      path: "/solutions/finance", 
      icon: "DocumentTime"
    },
    { 
      label: "Project Delivery", 
      description: "Improve Engagement. Increase Conversion", 
      path: "/solutions/pmo", 
      icon: "Optimize"
    }
  ]
})

const menuResources = ({
  label: "Resources",
  items: [
    { 
      label: "Articles", 
      description: "Learn and stay informed",
      path: "/articles", 
      icon: "Blog"
    },
    { 
      label: "Case Studies", 
      description: "Learn how analytics teams use Hippobyte.",
      path: "/articles/topics/case-study", 
      icon: "Impact"
    },
    { 
      label: "Documentation", 
      description: "Docs for Hippobyte Cloud Products",
      path: "/docs", 
      icon: "Notes"
    }
  ]
})

const menuPlatform= ({
  label: "Platform",
  items: [
    { 
      label: "Cloud Platform Security", 
      description: "Keeping your business data safe", 
      path: "/platform/security", 
      icon: "FingerPrint"
    },
    { 
      label: "Platform FAQ", 
      description: "Frequently Asked Questions", 
      path: "/platform/frequently-asked-questions", 
      icon: "Menu"
    },
    { 
      label: "Connector Directory",
      description: "Load data into your warehouse", 
      path: "/connectors", 
      icon: "Integration"
    },
  ]
})

const menuPricing= ({
  label: "Pricing",
  path: "/pricing"
})

const menuPartners= ({
  label: "Partners",
  path: "/company/partners"
})

export const menuData = {
  menuUseCases:  menuUseCases,
  menuResources: menuResources,
  menuPlatform:  menuPlatform,
  menuPricing:   menuPricing,
  menuPartners:  menuPartners,
  menuFooter: [
    { ...menuUseCases },
    { ...menuResources },
    {
      label: "Connectors",
      items: [
        { label: "View All", path: "/connectors" },
        { label: "ServiceNow", path: "/connectors/servicenow" },
        { label: "Clarity PPM", path: "/connectors/clarity-ppm" },
        { label: "Portfolio for JIRA", path: "/connectors/portfolio-for-jira" },
      ]
    },
    {
      label: "Support",
      items: [
        { label: "Legal and Security", path: "/legal" },
        { label: "Research Program", path: "/support/research" },
      ]
    },
    {
      label: "Company",
      items: [
        { label: "About Us", path: "/company/about" },
        { ...menuPartners },
      ]
    }
  ]
}