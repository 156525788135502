import React from 'react'
import styled from 'styled-components'

const FlowTop = () => {
  return (
    <Container width="2000" height="368" viewBox="0 0 2000 368" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M180 7H321.818C388.092 7 441.818 60.7258 441.818 127V127V127C441.818 193.274 495.544 247 561.818 247H670.182H980" stroke="#F67300"/>
      <path d="M180 7H-13" stroke="#F67300"/>
      <path d="M586 7V127C586 193.274 639.726 247 706 247H946" stroke="#F67300"/>
      <path d="M969 247H1329C1395.27 247 1449 300.726 1449 367V698" stroke="#F67300"/>
      <circle cx="310" cy="7" r="4" fill="#F67300" stroke="#F67300" stroke-width="4"/>
      <circle cx="729" cy="247" r="6" fill="#F67300"/>
      <circle cx="586" cy="7" r="4" fill="#F67300" stroke="#F67300" stroke-width="4"/>
    </Container>
  )
}

const Container = styled.svg`
  position: absolute;
  top: 17vh;
  right: 0;
  width: 36%;
  z-index: -3;
`

export default FlowTop