import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Box, Grid, Grommet, Heading, Text } from 'grommet'
import { DefaultLayout } from '../index'
import { theme } from '../../../style'
import { Footer, Header, Wave } from '../../../components' 
import { useOptions } from '../../../state/hooks' 

const HomeLayout = (props) => {
  const { setLocation } = useOptions() 
  const { location } = props

  useEffect(() => {
    setLocation(location)
  }, [location])

  return (
    <Grommet theme={theme}>
      <DefaultLayout 
        pad={{ "left": "xlarge" }} 
        location={location} 
        title="Hippobyte - No-Code Data Platform for the Enterprise"
      >
        <Grid
          rows={["auto", "flex", "small"]}
          columns={["full"]}
          areas={[
            ["header"],
            ["main"],
            ["footer"]
          ]}
          gap="small"
        >
          <Header 
            gridArea="header"
          />
          <MainSection 
            as="section" 
            gridArea="main"
          >
            <Box height="large" pad={{ horizontal: "xlarge" }}>
              <Heading weight={900}>
                Combine Content & Data From Anywhere
              </Heading>
            </Box>
            <Box 
              height="medium" 
              width={400}
              pad={{ horizontal: "xlarge" }}
              elevation="large"
              onClick={() => console.log("click")}
              hoverIndicator={true}
              margin="xlarge"
            >
              <Text weight={900}>
                Combine Content & Data From Anywhere
              </Text>
            </Box>
            <Wave color="#F4FCFF"/>
            <Footer gridArea="footer" />
          </MainSection>
        </Grid>
      </DefaultLayout>
    </Grommet>
  )
}

const MainSection = styled(Box)`
`

export default HomeLayout