import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Footer, Text, ResponsiveContext } from 'grommet'
import { AnchorLink, menuData } from '../../../components'
import { CookieBar, Copyright } from '../index'
import { useOptions } from '../../../state/hooks'

const FooterContainer = (props) => {
  const { options } = useOptions()
  const [marginBottom, setMarginBottom] = useState("0")

  useEffect(() => {
    const margin = options.privacyAccepted ? "0" : "2.5em"
    marginBottom !== margin && setMarginBottom(margin)
  }, [options.privacyAccepted])

  return (
    <ResponsiveContext.Consumer>
      {
        screenSize => {
          return (
            <Container>
              <CookieBar />
              <Footer 
                direction={screenSize === "small" && "column"}
                background="black" 
                pad={{ horizontal: "11vw", vertical: "large" }}
                align="start"
              >
                <FooterContent screenSize={screenSize} />
              </Footer>
              <Copyright screenSize={screenSize} marginBottom={marginBottom} />
            </Container>
          )
        }
      }
    </ResponsiveContext.Consumer>
  )
}

const FooterAnchor = ({ label, path }) => (
  <AnchorLink 
    path={path} 
  >
    <Text 
      weight="400" 
      size="1em" 
      color="white"
      style={{
        opacity: "0.7",
        lineHeight: "1.8em"
      }}
    >
      {label}
    </Text>
  </AnchorLink>
)

FooterAnchor.defaultProps = {
  highlight: false
}

const FooterContent = ({ screenSize }) => {
  return menuData.menuFooter.map((menu, index) => (
    <Box key={index}>
      <Text 
        weight="400" 
        size="medium" 
        color="light-0"
        margin={{
          top: screenSize === "small" && "small",
          bottom: "small"
        }}
        style={{
          textTransform: "uppercase"
        }}
      >
        {menu.label}
      </Text>
      <Box>
        {menu.items && menu.items.map((item, index) => (
          <FooterAnchor 
            key={`menuItem${index}`}
            label={item.label}
            path={item.path}
          />
        ))}
      </Box>
    </Box>
  ))
}

const Container = styled(Box)`
  @media print {
    footer {
      display: none;
    }
  }
`

export default FooterContainer