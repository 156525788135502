import React from 'react'
import { Box } from 'grommet'
import * as icons from './assets'

const Icon = (props) => {
  const { type, style, ...rest } = props
  
  return (
    <Box
      style={style}
    >
      {
        icons[type] && icons[type](rest)
      }
    </Box>
  )
}

export default Icon