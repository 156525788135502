import React from 'react'
import { Box, Text } from 'grommet'
import { Copyright } from '../..'

const DocsFooterContent = ({ next, previous }) => (
  <Box margin={{ bottom: "large" }}>
    <Questions />
    <Copyright 
      noticeOnly={true}
      background="transparent"
      pad="0"
      color="dark-2"
      weight={300}
    />
  </Box>
)

const Questions = () => {
  return (
    <Box margin={{ bottom: "large" }} round="xsmall" pad="small" border={{ side: "all", color: "brand-6" }} background="light-0">
      <Text weight={500} size="15px" color="dark-5"><strong>Have Questions?</strong> <a href="mailto:support@hippobyte.com">Reach out</a>, we are happy to address any questions you may have.</Text>
    </Box>
  )
}

export default DocsFooterContent