import React, { useEffect, useState } from 'react'
import { Box, Text } from 'grommet'
import { StatusGood } from 'grommet-icons'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

const Form = ({ defaultValues, validationSchema, onSubmit, formError, disabled, children }) => {
  const { errors, handleSubmit, setValue, clearErrors, register } = useForm({ 
    defaultValues: defaultValues, 
    resolver: yupResolver(validationSchema),
  })
  const [formPristine, setFormPristine] = useState(true)
  const [isError, setIsError] = useState()

  useEffect(() => {
    children.filter(item => item.props.input !== "Submit").map(child => {
      const { name } = child.props
      if (name) {
        register({ name: name })  
      }
    })
  }, [children])

  useEffect(() => {
    isError !== formError && setIsError(formError)
  }, [formError])

  const onFormSubmit = (data) => {
    setFormPristine(true)
    onSubmit && onSubmit(data)
  }

  const onInputChange = () => {
    setFormPristine(false)
    setIsError(false)
  }

  const isInputDisabled = (props) => {
    if (props.input === "Submit") {
      return formPristine || Object.keys(errors).length > 0 || disabled || props.disabled
    }
    return disabled || props.disabled
  }

  return (
    <Box  
      onSubmit={handleSubmit(onFormSubmit)} 
      direction="row"
      justify="between"
      wrap={true}
      height="large"
      as="form"
    >
      { 
        isError && (
          <Box 
            fill="horizontal" 
            margin={{ horizontal: "small", top: "-20px", bottom: "15px" }} 
            background="red" 
            border={{ color: "red" }}
            pad="small" 
            round="xsmall"
          >
            <Text size="16px" color="white" weight="600">An error occurred while processing your request. Refresh your browswer and try again.</Text>
          </Box>
        )
      }
      {
        disabled && (
          <Box fill="horizontal" align="center" margin={{ top: "10em" }}>
            <StatusGood size="12em" color="brand" />
            <Text textAlign="center" size="large" weight="900" margin={{ top: "large", bottom: "small" }}>Your request has been received.</Text>
            <Text textAlign="center" size="large">We will reach out shortly.</Text>
          </Box>
        )
      }
      {
        !disabled && (
          Array.isArray(children) ? 
            children.map(child => {
              return child.props.name ? 
                React.createElement(child.type, {
                  ...{
                    ...child.props,
                    setValue: setValue,
                    clearErrors: clearErrors,
                    onChange: onInputChange,
                    error: errors[child.props.name] && errors[child.props.name].message,
                    disabled: isInputDisabled(child.props),
                    key: child.props.name
                  }
                })
                : child
            })
            : children
        )
      }
    </Box>
  )
}

export default Form