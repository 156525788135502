import React from 'react'
import styled from 'styled-components'
import { 
  BiToolsDesktop, BackgroundRight, Circle, Connect, Connected, Curve, Diamond, Dots, 
  FlowTop, Gradient, Wave, Welcome
} from '../index'

const Background = (props) => {
  const { backgroundImages } = props

  const BackgroundImage = ({ type, ...rest }) => {
    switch(type) {
      case "bi-tools-desktop":
        return <BiToolsDesktop {...rest} />
      case "background-right":
        return <BackgroundRight {...rest} />
      case "circle":
        return <Circle {...rest} />
      case "connect":
        return <Connect {...rest} />
      case "connected":
        return <Connected {...rest} />
      case "curve":
        return <Curve {...rest} />
      case "diamond":
        return <Diamond {...rest} />
      case "dots":
        return <Dots {...rest} />
      case "flowTop":
        return <FlowTop {...rest} />
      case "gradient":
        return <Gradient {...rest} />
      case "welcome":
        return <Welcome {...rest} />
      case "wave":
        return <Wave {...rest} />
      default:
        return null
    }
  }

  return (
    <Container>
      {
        backgroundImages 
          && Array.isArray(backgroundImages) 
          && backgroundImages.map((options, index) => {
            const { type, ...rest } = options
            return (
              <BackgroundImage 
                key={index} 
                type={type}
                {...rest}
              />
            )
          }
          )
      }
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;
  overflow: visible;

  &svg:not(:root) {
    overflow: hidden;
  }
`

Background.defaultProps = {
  backgroundType: null
}

export default Background