import React from 'react'
import { navigate } from 'gatsby'
import { Box, ResponsiveContext, Menu, Text } from 'grommet'
import { AnchorLink } from '../..'

const Tags = ({ tags, label, path, pathname }) => {
  return (
    <ResponsiveContext.Consumer>
      {
        screenSize => {
          if (["small", "medium"].includes(screenSize)) { 
            return (
              <SmallScreenLayout 
                tags={tags} 
                label={label} 
                path={path} 
                pathname={pathname}
              />
            )
          }

          return (
            <Box direction="row" gap="small">
              <Box margin={{ left: "small" }}>
                <TaggedAnchorLink 
                  path={path}
                  label={label}
                  color={path === pathname ? "brand-3" : "brand-6"}
                />
              </Box>
              {
                tags.map((tag, index) => (
                  <TaggedAnchorLink 
                    key={index}
                    path={tag.path}
                    label={tag.label}
                    color={tag.path === pathname ? "brand-3" : "brand-6"}
                  />
                ))
              }
            </Box>
          )
        }
      }
    </ResponsiveContext.Consumer>
  )
}

const SmallScreenLayout = ({ tags, label, path, pathname }) => {
  const tagged = [{ label: label, path: path }, ...tags]

  const items = tagged.map((tag, index) => ({ 
    label: <Text key={index} margin="xxsmall" color="black" size="small" weight={600}>{tag.label}</Text>,
    disabled: tag.path === pathname,
    onClick: () => navigate(tag.path)
  }))

  return (
    <Menu items={items} />
  )
}

const TaggedAnchorLink = ({ path, label, color }) => (
  <AnchorLink path={path} label={label} color={color} />
)


Tags.deafultProps = {
  tags: []
}

export default Tags