import React from 'react'
import styled from 'styled-components'

const Diamond = (props) => {
  const { backgroundColor } = props
  return (
    <Container xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1031 733">
      <defs>
        <path id="b" d="M512.71-391.875L944.045 39.46c39.833 39.834 39.833 104.416 0 144.25L512.71 615.045c-39.834 39.833-104.416 39.833-144.25 0L-62.875 183.71c-39.834-39.834-39.834-104.416 0-144.25L368.46-391.875c39.834-39.834 104.416-39.834 144.25 0z"/>
        <filter id="a" width="119.7%" height="119.7%" x="-9.8%" y="-7%" filterUnits="objectBoundingBox">
          <feOffset dy="30" in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="30"/>
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.105882353 0 0 0 0 0.0784313725 0 0 0 0 0.254901961 0 0 0 0.05 0"/>
        </filter>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="0%">
          <stop offset="0%" style={{ stopColor: {backgroundColor}, stopOpacity: "1" }} />
          <stop offset="100%" style={{ stopColor: "#fff", stopOpacity: "1" }} />
        </linearGradient>
      </defs>
      <g fill="none" fill-rule="evenodd" opacity=".4">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <use fill="#fff" xlinkHref="#b" />
      </g>
    </Container>
  )
}

const Container = styled.svg`
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -3;
`

export default Diamond