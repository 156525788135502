import React from 'react'
import { Box, Text } from 'grommet'
import { Close, Checkmark } from 'grommet-icons'
import { PriceDescription } from '../index'
import { PageTitle } from '../../../components/layout'

const PriceList = () => {
  const data = [
    {
      options: [
        {
          key: 3,
          label: "Source Connectors",
          description: "Number of source connectors."
        },
        {
          key: 4,
          label: "Destination Connectors",
          description: "Number of destination connectors."
        },
        {
          key: 5,
          label: "Custom Connectors",
          description: "Support for custom connectors."
        },
        {
          key: 6,
          label: "QA Environment",
          description: "Data Quality Management"
        },
        {
          key: 7,
          label: "Customer support",
          description: "Dedicated operations team support and assurance."
        },
        {
          key: 8,
          label: "Sync Frequency",
          description: "Data Quality Management"
        },
        {
          key: 9,
          label: "Cron Scheduling",
          description: "Data Quality Management"
        },
        {
          key: 10,
          label: "Onboarding",
          description: "Uptime of source consumer(s) and destination producer(s)."
        },
        {
          key: 11,
          label: "Data Quality Console",
          description: "A user interface to monitor data events."
        },
        {
          key: 12,
          label: "Uptime SLA",
          description: "Uptime of source consumer(s) and destination producer(s)."
        }
      ]
    },
    {
      label: "Starter",
      options: [
        {
          key: 3,
          value: "1"
        },
        {
          key: 4,
          value: "1"
        },
        {
          key: 7,
          value: "Online Chat"
        },
        {
          key: 8,
          value: "Every 1 hour"
        },
        {
          key: 11,
          value: true
        }
      ]
    },
    {
      label: "Growth",
      options: [
        {
          key: 3,
          value: "Unlimited"
        },
        {
          key: 4,
          value: "3"
        },
        {
          key: 6,
          value: "Optional / 1"
        },
        {
          key: 7,
          value: "Priority Online Chat"
        },
        {
          key: 8,
          value: "Every 15 minutes"
        },
        {
          key: 9,
          value: false
        },
        {
          key: 10,
          value: "Optional"
        },
        {
          key: 11,
          value: true
        },
        {
          key: 12,
          value: "99.9%",
        }
      ]
    },
    {
      label: "Enterprise",
      options: [
        {
          key: 3,
          value: "Unlimited"
        },
        {
          key: 4,
          value: "Unlimited"
        },
        {
          key: 5,
          value: true
        },
        {
          key: 6,
          value: "Custom / Multiple",
          highlight: true
        },
        {
          key: 7,
          value: "1 hour response time",
          highlight: true
        },
        {
          key: 8,
          value: "Every 60 seconds",
          highlight: true
        },
        {
          key: 9,
          value: true
        },
        {
          key: 10,
          value: "Custom",
          highlight: true
        },
        {
          key: 11,
          value: true
        },
        {
          key: 12,
          value: "99.99%",
          highlight: true
        }
      ]
    }
  ]

  const options = data[0] && data[0].options

  const boxStyle = (index) => {
    const defaultStyle = { 
      border: "1px solid #cbdbda"
    }
    switch(index) {
      case 0:
        return { }
      case 1:
        return { background: "white", round: { corner: "top-left", size: "small" }, style: { ...defaultStyle, borderRight: 0 }}
      case 2:
        return { background: "white", style: { ...defaultStyle }}
      case 3:
        return { background: "white", round: { corner: "right", size: "small" }, style: { ...defaultStyle, borderLeft: 0  }}
      default:
        return {}
    }
  }

  const headerProps = [
    {
    },
    {
      round: {
        corner: "top-left",
        size: "small"
      },
      image: "ConnectTool"
    },
    {
      image: "Motherboard"
    },
    { 
      round: {
        corner: "top-right",
        size: "small"
      },
      image: "Puzzle"
    }
  ] 
  
  return (
    <Box>
      <PageTitle
        label="Features for every team size."
        margin={{
          horizontal: "6.5vw",
        }}
      />
      <Box
        direction="row"
        justify="start"
        margin={{
          top: "large",
          horizontal: "6.6vw"
        }}
        width={{
          min: "large"
        }}
      >
        {
          data && data.map((item, itemIndex) => (
            <Box
              key={itemIndex}
              basis="1/4"
              {...boxStyle(itemIndex)}
            >
              <ListHeader
                label={item.label}
                description={item.description}
                {...headerProps[itemIndex]}
              />
              <Box
                round={itemIndex === 0 && { corner: "left", size: "small" }}
                style={itemIndex === 0 ? { border: "1px solid #cbdbda", borderRight: 0 } : {}}
                background={itemIndex === 0 && "white"}
              >
                {
                  options.map((option, optionIndex) => (
                    <Box
                      key={`${option.key}-${itemIndex}-${optionIndex}`}
                      height="56px"
                      justify="center"
                      pad="small"
                      border={{
                        side: "top",
                        color: "light-2",
                        size: "xsmall"
                      }}
                    >
                      <ListOption
                        option={option}
                        priceOptions={item.options}
                      />
                    </Box>
                  ))
                }
              </Box>
            </Box>
          ))
        }
      </Box>
    </Box>
  )
}

const ListHeader = ({ label, description, image, ...rest }) => {
  return (
    <Box
      align="center"
      justify="center"
      height="70px"
      background={label && "brand-3"}
      {...rest}
    >
      <Text color="white" weight={900} size="large">
        {label}
      </Text>
    </Box>
  )
}

const ListOption = ({ option, priceOptions }) => {
  const priceOption = priceOptions.find(item => item.key === option.key)
  
  if (priceOption && priceOption.label) {
    return <PriceDescription label={priceOption.label} description={priceOption.description} />
  } else {
    return <PriceOption option={priceOption || {}} />
  }
}

const PriceOption = ({ option }) => {

  const OptionValue = () => {
    switch(option.value) {
      case undefined:
      case false:
        return <Close color="crimson" size="18px" />
      case true:
        return <Checkmark color="brand-3" size="18px" />
      default:
        return (
          <Text 
            weight={option.highlight ? 900 : 600} 
            color={option.highlight ? "brand" : "dark-1"}
          >
            {option.value}
          </Text>
        )
    }
  }

  return (
    <Box align="center">
      <OptionValue />
    </Box>
  )
}

export default PriceList