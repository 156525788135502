import React, { useState } from 'react'
import * as yup from 'yup'
import { Box } from 'grommet'
import { useApi } from '../../../state/hooks'
import { Form, FormInput } from '../../../components'

const ContactForm = ({ ...rest }) => {
  const [disabled, setDisabled] = useState(false)
  const [formError, setFormError] = useState(false)
  const { postData } = useApi()
  const validationSchema = yup.object().shape({
    first_name: yup.string().required("First name is required."),
    last_name: yup.string().required("Last name is required."),
    email: yup.string().email("Email is invalid.").required("Email is required."),
    company: yup.string().required("Company name is required."),
    information: yup.string()
  })

  const onSubmit = (data) => {
    postData(`https://formspree.io/f/mzbkwnpz`, data)
      .then(response => {
        console.log(response)
        if (response === false) {
          setFormError(true)
        } else {
          setDisabled(true)  
        }
      })
  }

  return (
    <Box 
      round="small" 
      border={{
        color: "light-3",
        size: "xsmall" 
      }}
      pad={{ vertical: "large", horizontal: "medium" }}
      margin={{ top: "-40px" }}
      {...rest}
    >
      <Form
        onSubmit={(data) => onSubmit(data)}
        validationSchema={validationSchema}
        formError={formError}
        disabled={disabled}
      >
        <FormInput
          name="first_name"
          input="TextInput"
          label="First Name"
          required
          placeholder="Enter first name"
          flexBasis="1/2"
        />
        <FormInput
          name="last_name"
          input="TextInput"
          label="Last Name"
          required
          placeholder="Enter last name"
          flexBasis="1/2"
        />
        <FormInput
          name="email"
          input="TextInput"
          label="Business E-mail"
          required
          placeholder="Enter business email"
        />
        <FormInput
          name="company"
          input="TextInput"
          label="Company Name"
          required
          placeholder="Enter company name"
        />
        <FormInput
          name="information"
          input="TextArea"
          rows="15"
          label="Additional Information"
          placeholder="Tell us a little more about the challenge you are interesting in solving."
        />
        <FormInput
          name="submit"
          input="Submit"
          label="Book a Meeting"
        />
      </Form>
    </Box>
  )
}

export default ContactForm