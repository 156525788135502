import React from 'react'
import styled from 'styled-components'

const Circle = (props) => {
  const { backgroundColor } = props

  return (
    <Container width="838px" height="838px" viewBox="0 0 838 838" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="419" cy="419" r="419" fill={backgroundColor || "#FCFAFF"} />
    </Container>
  )
}

const Container = styled.svg`
  position: absolute;
  top: 17vh;
  right: 0;
  width: 36%;
  z-index: -3;
`

export default Circle