import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Box, Layer, Markdown, Text } from 'grommet'
import { Close } from 'grommet-icons'
import { AnchorLink, DocsMarkdown } from '../../../components'
import { slugger } from '../../../utils'

const MessageBar = () => {
  const [dismissedState, setDismissedState] = useState(false)
  const data = useStaticQuery(graphql`
    query Messages {
      items: allMarkdownRemark(
          filter: {
            frontmatter: {
              templateKey: {
                eq: "messages"
              }
            }
          }, 
          sort: {
            fields: frontmatter___date,
            order: DESC
          }
          limit: 1
        ) {
        messages: edges {
          message: node {
            frontmatter {
              title
              description
              order
              modal
              date
            }
            fields {
              slug
            }
            html
          }
        }
      }
    }
  `)

  const messages = data.items.messages
  const message = messages.length > 0 ? messages[0].message : null
  const localStorageItemName = message ? slugger([message.fields.slug]) : "message-bar-dismissed"
  const ttl = message ? message.frontmatter.order : 90

  useEffect(() => {
    const timestamp = Math.round(new Date().getTime() / 1000)
    const dismissedOn = localStorage.getItem(`${localStorageItemName}-on`)
    const dismissedTime = dismissedOn ? Date.parse(dismissedOn) / 1000 : 0
    const dismissed = localStorage.getItem(localStorageItemName) === "true"

    if (timestamp - dismissedTime > ttl) {
      resetAcceptance()
      return 
    }

    if (dismissed) { 
      setDismissedState(true)
      return
    }
  }, [])

  const setAcceptance = () => {
    localStorage.setItem(localStorageItemName, "true")
    localStorage.setItem(`${localStorageItemName}-on`, new Date())
    setDismissedState(true)
  }

  const resetAcceptance = () => {
    localStorage.removeItem(localStorageItemName)
    localStorage.removeItem(`${localStorageItemName}-on`)
    setDismissedState(false)
  }

  const handleClick = () => {
    setAcceptance()
  }

  if (message.frontmatter.modal === "true") {
    return (
      <Layer modal>
        <Box
          gap="small"
          direction="row"
          pad={{ horizontal: "large", vertical: "medium" }}
        >
          <Box
            overflow="scroll"
            pad={{ bottom: "large" }}
            width="medium"
          >
            <DocsMarkdown
              dangerouslySetInnerHTML={{ __html: message.html }}
            />
          </Box>
        </Box>
      </Layer>
    )
  }

  return (
    <>
    {
      message && !dismissedState && (
        <Box 
          direction="row"
          width="full"
          background="accent-1" 
          align="center" 
          pad={{ vertical: "small" }} 
          animation={{
            type: "slideDown",
            delay: 1400,
            duration: 900,
            size: "xlarge"
          }}
        >
          <Box
            basis="full"
            align="center"
          >
            <Text 
              color="white" 
              weight="700" 
              size="medium">
                {message.frontmatter.title}
                {
                  message.frontmatter.description && (
                    <AnchorLink
                      onClick={setAcceptance}
                      label={<Text size="small" color="white" weight="500" margin={{ left: "xsmall" }}>read more...</Text>}
                      path={message.frontmatter.description}
                    />
                  )
                }
            </Text>
          </Box>
          <Box
            basis="auto"
          >
            <Close 
              color="white"
              size="18px"
              onClick={handleClick}
              style={{
                position: "relative", 
                top: "1px", 
                cursor: "pointer", 
                marginRight: "2em",
                float: "right"
              }} 
            />
          </Box>
        </Box>
      )
    }
    </>
  )
}

export default MessageBar