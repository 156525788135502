import React from 'react'
import styled, { css } from 'styled-components'
import { TextArea as Input } from 'grommet'

const TextArea = ({ onChange, ...rest }) => {
  return (
    <Container
      onChange={event => onChange && onChange(event.target.value)}
      {...rest}
    />
  )
}

TextArea.defaultProps = {
  size: "small"
}

const Container = styled(Input)`
  background: white;

  ${props => props.size === "small" && css`
    padding: 8px 12px;
  `}
`

export default TextArea