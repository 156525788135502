import React, { forwardRef } from 'react'
import { Box } from 'grommet'
import { DocsFooter, DocsMarkdown } from '../index'

const DocsContent = ({ data, ref }) => {
  const { post, next, previous } = data
  return (
    <Box basis="3/4" pad={{ right: "large" }} ref={ref}>
      <DocsMarkdown dangerouslySetInnerHTML={{ __html: post.html }} />
      <DocsFooter 
        next={next && next}
        previous={previous && previous}
      />
    </Box>
  )
}

export default forwardRef(DocsContent)