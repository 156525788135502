import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Button, Layer, Text } from 'grommet'
import { Menu as MenuIcon, Close as MenuCloseIcon } from 'grommet-icons'
import { MenuItem, menuData } from '../index'
import { AnchorButton, AnchorLink, Logo } from '../../../components'

const Menu = ({ screenSize, logo, menu, primary, actions, pageTheme, ...rest }) => {
  const [dropMenuActive, setDropMenuActive] = useState(false)
  const { menuUseCases, menuResources, menuPlatform, menuPricing, menuPartners } = menuData

  const Primary = () => {
    return (
      <Box 
        direction={screenSize === "small" ? "column" : "row"}
        justify="start"
        flex="grow"
      >
        <MenuItem 
          theme={pageTheme} 
          screenSize={screenSize}
          {...menuPlatform}
        />
        <MenuItem
          theme={pageTheme} 
          screenSize={screenSize}
          {...menuUseCases}
        />
        <MenuItem 
          theme={pageTheme} 
          screenSize={screenSize}
          {...menuResources}
        />
        <MenuItem 
          theme={pageTheme} 
          screenSize={screenSize}
          {...menuPricing}
        />
      </Box>
    )
  }

  const Actions = (props) => {
    const { button } = props
    const actionText = screenSize === "medium" ? "Demo" : "Schedule Demo"

    return (
      <Box 
        direction="row" 
        align="center"
        justify="end"
        margin={{ left: screenSize }}
      >
        {
          button && button.default && (
            <MenuItem
              type="action"
              label={
                <AnchorButton 
                  label={<Text size="small" truncate="true">{actionText}</Text>}
                  size="small"
                  primary={pageTheme !== "light"}
                  color={pageTheme === "light" && "white"}
                  path="/contact/schedule-demo"
                />
              }
            />
          )
        }
        {
          button && !button.default && button
        }
      </Box>
    )
  }

  const MenuBar = () => {
    return (
      <Container 
        direction="row" 
        align="center"
        pad={{ horizontal: "11vw", vertical: "small" }} 
      >
        <Box>
          { logo && <AnchorLink path="/" label={<Logo screenSize={screenSize} theme={pageTheme} {...logo} />} /> }
        </Box>
        <Box direction="row" basis="full" justify="center">
          { primary && <Primary {...primary} /> }
          { actions && <Actions {...actions} /> }
        </Box>
      </Container>
    )
  }

  const MenuDrop = () => {
    return (
      <Container 
        direction="row" 
        align="center"
        pad={{ horizontal: "11vw", top: "large", bottom: "small" }} 
      >
        <Button
          icon={<MenuIcon />}
          margin={{ right: "small" }}
          onClick={() => setDropMenuActive(true)}
          plain
        />
        <Box>
          { logo && <AnchorLink path="/" label={<Logo showLogo={false} theme={pageTheme} {...logo} />} /> }
        </Box>
        {
          dropMenuActive && (
            <Layer
              onEsc={() => setDropMenuActive(false)}
              onClickOutside={() => setDropMenuActive(false)}
            >
              <Box pad={{ vertical: "medium", horizontal: "11vw" }} margin={{ bottom: "xlarge" }} overflow={{ vertical: "auto" }}>
                <Box direction="row" align="center">
                  <Box>
                    { logo && <AnchorLink path="/" label={<Logo showLogo={false} theme={pageTheme} {...logo} />} /> }
                  </Box>
                  <Box align="end" flex="grow">
                    <Button icon={<MenuCloseIcon />} onClick={() => setDropMenuActive(false)} />
                  </Box>
                </Box>
                <Box>
                  <Primary />
                </Box>
              </Box>
            </Layer>
          )
        }
      </Container>
    )
  }

  if (screenSize === "small") {
    return (
      <MenuDrop />
    )
  }

  return <MenuBar />
}

Menu.defaultProps = {
  logo: {},
  menu: {},
  primary: {},
  actions: {
    button: {
      default: true
    }
  }
}

const Container = styled(Box)`
  @media print {
    display: none;
  }
`

export default Menu