import React from 'react'
import styled, { keyframes } from 'styled-components'

const Connected = () => (
  <Container>
    <svg width="807px" height="762px" viewBox="0 0 807 762" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>
          HippobyteConnectorColor
      </title>
      <defs>
          <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="85" height="85" fill="white">
              <use xlinkHref="#path-1"/>
          </mask>
          <mask id="mask-4" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="85" height="85" fill="white">
              <use xlinkHref="#path-3"/>
          </mask>
          <mask id="mask-6" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="90" height="90" fill="white">
              <use xlinkHref="#path-5"/>
          </mask>
          <mask id="mask-8" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="90" height="90" fill="white">
              <use xlinkHref="#path-7"/>
          </mask>
          <mask id="mask-10" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="210" height="210" fill="white">
              <use xlinkHref="#path-9"/>
          </mask>
          <mask id="mask-12" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="136" height="136" fill="white">
              <use xlinkHref="#path-11"/>
          </mask>
          <mask id="mask-14" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="245.000345" height="245.000345" fill="white">
              <use xlinkHref="#path-13"/>
          </mask>
          <circle id="path-1" cx="342.5" cy="713.5" r="42.5"/>
          <circle id="path-3" cx="127.689169" cy="293.217954" r="42.5"/>
          <circle id="path-9" cx="126" cy="126" r="105"/>
          <circle id="path-11" cx="126" cy="126" r="68"/>
          <circle id="path-13" cx="125.570768" cy="125.570768" r="122.500172"/>
          <polygon id="path-5" points="45 554 83.9711432 576.5 83.9711432 621.5 45 644 6.02885683 621.5 6.02885683 576.5"/>
          <polygon id="path-7" points="267 -1.20792265e-13 305.971143 22.5 305.971143 67.5 267 90 228.028857 67.5 228.028857 22.5"/>
      </defs>
      <g id="HippobyteConnectorColor" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Shapes" transform="translate(5.000000, 0.000000)">
              <path d="M694,540 L759,540 C764.522847,540 769,544.477153 769,550 L769,615 C769,620.522847 764.522847,625 759,625 L694,625 C688.477153,625 684,620.522847 684,615 L684,550 C684,544.477153 688.477153,540 694,540 Z" id="Rectangle" stroke="#44D7B6" fill="#44D7B6" fillOpacity="0.15" strokeDasharray="75,4"/>
              <use id="OvalBottom" stroke="#B620E0" mask="url(#mask-2)" strokeWidth="2" fill="#B620E0" fillOpacity="0.15" strokeDasharray="65,4" xlinkHref="#path-1"/>
              <use id="OvalLeft" stroke="#E56A54" mask="url(#mask-4)" strokeWidth="2" fill="#E56A54" fillOpacity="0.15" strokeDasharray="65,4" transform="translate(127.689169, 293.217954) rotate(1.000000) translate(-127.689169, -293.217954) " xlinkHref="#path-3"/>
              <use id="PolygonBottom" stroke="#DCE54B" mask="url(#mask-6)" strokeWidth="2" fill="#DCE54B" fillOpacity="0.15" strokeDasharray="64,4" transform="translate(45.000000, 599.000000) rotate(-270.000000) translate(-45.000000, -599.000000) " xlinkHref="#path-5"/>
              <use id="PolygonTop" stroke="#59C155" mask="url(#mask-8)" strokeWidth="2" fill="#59C155" fillOpacity="0.15" strokeDasharray="64,4" transform="translate(267.000000, 45.000000) rotate(-270.000000) translate(-267.000000, -45.000000) " xlinkHref="#path-7"/>
              <path d="M754.5,184 C777.972102,184 797,164.972102 797,141.5 C797,118.027898 777.972102,99 754.5,99 C731.027898,99 712,118.027898 712,141.5 C712,164.972102 731.027898,184 754.5,184 Z" id="OvalTop" fill="#0091FF" stroke="#0091FF" fillOpacity="0.15"/>
          </g>
          <g id="Rotate" transform="translate(350.000000, 261.000000)" strokeWidth="2">
              <use id="Outer" stroke="#4D5FE8" mask="url(#mask-10)" fill="#FFFFFF" strokeWidth="3" strokeDasharray="1,3" xlinkHref="#path-9"/>
              <use id="Inner" stroke="#4D5FE8" mask="url(#mask-12)" fill="#F9F9FD" strokeDasharray="4,4" xlinkHref="#path-11"/>
              <use id="Main" stroke="#4D5FE8" mask="url(#mask-14)" fillOpacity="0.00445257867" fill="#B620E0" strokeWidth="4" strokeDasharray="60,10" transform="translate(125.570768, 125.570768) rotate(1.000000) translate(-125.570768, -125.570768) " xlinkHref="#path-13"/>
          </g>
          <g id="Connectors" transform="translate(94.557239, 88.466170)" strokeDasharray="0,3" strokeLinecap="square">
              <line x1="82.424937" y1="231.766699" x2="247.806996" y2="269.596059" id="Connector-6" stroke="#E56A54" transform="translate(165.267793, 250.632659) rotate(4.000000) translate(-165.267793, -250.632659) "/>
              <line x1="3.13863753" y1="492.575109" x2="260.849272" y2="361.485251" id="Connector-5" stroke="#d0d836" transform="translate(132.125548, 427.403624) rotate(2.000000) translate(-132.125548, -427.403624) "/>
              <line x1="260.593519" y1="567.283846" x2="354.852119" y2="436.771912" id="Connector-4" stroke="#B620E0" transform="translate(307.511469, 501.840248) rotate(-11.000000) translate(-307.511469, -501.840248) "/>
              <line x1="476.783656" y1="399.275071" x2="583.741025" y2="464.265538" id="Connector-3" stroke="#44D7B6" transform="translate(529.983489, 431.313208) rotate(4.999999) translate(-529.983489, -431.313208) "/>
              <line x1="484.571144" y1="206.271281" x2="628.320074" y2="96.7895691" id="Connector-2" stroke="#32C5FF" transform="translate(556.581971, 151.647386) rotate(-3.415793) translate(-556.581971, -151.647386) "/>
              <line x1="211.114606" y1="3.29846736" x2="307.769952" y2="186.783464" id="Connector-1" stroke="#6DD400" transform="translate(259.238124, 95.023599) rotate(-2.000000) translate(-259.238124, -95.023599) "/>
          </g>
          <g id="BarChart" transform="translate(428.000000, 362.000000)" fillRule="nonzero">
              <path d="M90,49 L90,49 C86.688,49 84,47.0043636 84,44.5454545 L84,4.45454545 C84,1.99563636 86.688,0 90,0 L90,0 C93.312,0 96,1.99563636 96,4.45454545 L96,44.5454545 C96,47.0043636 93.312,49 90,49 Z" id="Bar-5" fill="#D1D1EB"/>
              <path d="M69,49 C65.688,49 63,47.0043636 63,44.5454545 L63,4.45454545 C63,1.99563636 65.688,0 69,0 C72.312,0 75,1.99563636 75,4.45454545 L75,44.5454545 C75,47.0043636 72.312,49 69,49 Z" id="Bar-4" fill="#D1D1EB"/>
              <path d="M48,49 L48,49 C44.688,49 42,47.016 42,44.5714286 L42,22.4285714 C42,19.984 44.688,18 48,18 L48,18 C51.312,18 54,19.984 54,22.4285714 L54,44.5714286 C54,47.016 51.312,49 48,49 Z" id="Bar-3" fill="#D1D1EB"/>
              <path d="M27,49 C23.688,49 21,47.0088889 21,44.5555556 L21,13.4444444 C21,10.9911111 23.688,9 27,9 C30.312,9 33,10.9911111 33,13.4444444 L33,44.5555556 C33,47.0088889 30.312,49 27,49 Z" id="Bar-2" fill="#A3AEFF"/>
              <path d="M6,49 C2.688,49 0,47.1184 0,44.8 L0,32.2 C0,29.8816 2.688,28 6,28 C9.312,28 12,29.8816 12,32.2 L12,44.8 C12,47.1184 9.312,49 6,49 Z" id="Bar-1" fill="#D1D1EB"/>
          </g>
      </g>
    </svg>
  </Container>
)

const mainOvalAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const connectorPulse = keyframes`
  20%, 100% {
    stroke-dasharray: unset;
  }
  0% {
    stroke-dasharray: unset;
  }
  10% {
    stroke-dasharray: none;
    stroke-width: 2px;
  }
`

const shapePulse = keyframes`
  20%, 100% {
    stroke-dashoffset: 1000;
  }
  0% {
    stroke-dashoffset: 1000;
  }
  10% {
    stroke-dashoffset: 0;
  }
`

const barAnimation = keyframes`
  20%, 100% {
    transform: scaleY(1) translateY(0);
    fill: #D1D1EB;
  }
  0% {
    transform: scaleY(1) translateY(0);
    fill: #D1D1EB;
  }
  10% {
    transform: scaleY(1.3) translateY(-11px);
    fill: #4D5FE8;
  }
`

const Container = styled.div`
  & > svg {
    position: absolute;
    top: 0;
    right: 4vw;
    width: 640px;

    & #Main, & #Inner {
      transform-box: fill-box;
      transform-origin: center;
    }

    & #Main {
      animation: ${mainOvalAnimation} 2.5s 2s infinite cubic-bezier(0.1, 0.6, 0.8, 0.3) forwards;
    }

    & #Inner {
      animation: ${mainOvalAnimation} 10s 2s infinite linear reverse;
    }

    & #PolygonTop, & #PolygonBottom, & #OvalLeft, & #OvalBottom, & #OvalTop, & #Rectangle {
      animation: ${shapePulse} 1s 2s infinite ease-in-out;
    }

    & #Bar-1 {
      animation: ${barAnimation} 2.4s 4s infinite cubic-bezier(1.0, 1.0, 0.9, 0.6) forwards;
    }

    & #Bar-2 {
      animation: ${barAnimation} 4.2s 4s infinite cubic-bezier(1.0, 1.0, 0.9, 0.5) forwards;
    }

    & #Bar-3 {
      animation: ${barAnimation} 3.2s 4s infinite cubic-bezier(0.5, 0.5, 0.9, 0.7) forwards;
    }

    & #Bar-4 {
      animation: ${barAnimation} 6.2s 4s infinite cubic-bezier(0.8, 1.0, 0.9, 0.3) forwards;
    }

    & #Bar-5 {
      animation: ${barAnimation} 7.2s 4s infinite cubic-bezier(1.0, 0.4, 0.9, 0.8) forwards;
    }

    & #Connector-1 {
      animation: ${connectorPulse} 1.4s infinite linear;
    }
    
    & #Connector-2 {
      animation: ${connectorPulse} 1.8s infinite linear;
    }

    & #Connector-3 {
      animation: ${connectorPulse} 1.1s infinite linear;
    }

    & #Connector-4 {
      animation: ${connectorPulse} 1.8s infinite linear;
    }

    & #Connector-5 {
      animation: ${connectorPulse} 2.0s infinite linear;
    }

    & #Connector-6 {
      animation: ${connectorPulse} 2.4s infinite linear;
    }

    & #Bar-1 {
      transform: 
    }
  }
`

export default Connected