import React from 'react'
import { ContainerList } from '../..'

const DocsNavIndex = () => {
  return (
    <ContainerList
      pad={{ bottom: "large" }}
      margin={{ vertical: "medium" }}
      fontSize="small"
      items={[
        {
          title: "Files",
          description: "Built for analysts, Hippobyte Connectors are truly a a one-click operation.",
          basis: "1/2",
          height: "xsmall",
          image: {
            src: "Folder",
            position: "left",
            justify: "start",
            size: "44px",
            theme: "black"
          }
        },
        {
          title: "Applications",
          description: "Built for analysts, Hippobyte Connectors are truly a a one-click operation. ",
          basis: "1/2",
          height: "xsmall",
          image: {
            src: "TrueFalse",
            position: "left",
            justify: "start",
            size: "44px",
            theme: "black"
          }
        },
        {
          title: "Databases",
          description: "Built for analysts, Hippobyte Connectors are truly a a one-click operation. Hippobyte  takes care of all the background work to support schema changes so you can focus on your data.",
          basis: "1/2",
          height: "xsmall",
          image: {
            src: "DataBackup",
            position: "left",
            justify: "start",
            size: "44px",
            theme: "black"
          }
        },
        {
          title: "Monitoring",
          description: "Built for analysts, Hippobyte Connectors are truly a a one-click operation. Hippobyte  takes care of all the background work to support schema changes so you can focus on your data.",
          basis: "1/2",
          height: "xsmall",
          image: {
            src: "Motherboard",
            position: "left",
            justify: "start",
            size: "44px",
            theme: "black"
          }
        }
      ]}
    />
  )
}

export default DocsNavIndex