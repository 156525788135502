import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Box, Drop, Text } from 'grommet'

const PriceDescription = ({ label, description }) => {
  const [over, setOver] = useState(false)
  const ref = useRef()

  return (
    <Box
      direction="row"
      justify="end"
      align="center"
    >
    <Text
      color="dark-1"
      weight={700}
    >
      {label}
    </Text>
    <QuestionMark
      ref={ref}
      onMouseOver={() => setOver(true)}
      onMouseOut={() => setOver(false)}
      onFocus={() => {}}
      onBlur={() => {}}
      background="light-3"
      width="18px"
      height="18px"
      round="50%"
      margin={{ horizontal: "1.15em" }}
      style={{ cursor: "pointer" }}
    />
    {description && ref.current && over && (
      <Drop align={{ right: "left" }} target={ref.current} plain>
        <Box
          margin="xsmall"
          pad={{
            horizontal: "medium",
            vertical: "small"
          }}
          elevation="small"
          round="xsmall"
          background="dark-3"
          width="275px"
        >
          <Text
            weight={700}
            color="white"
            size="small"
            margin={{
              bottom: "6px"
            }}
          >
            {label}
          </Text>
          <Text
            weight={400}
            color="white"
            size="small"
          >
            {description}
          </Text>
        </Box>
      </Drop>
    )}
  </Box>
  )
}

const QuestionMark = styled(Box)`
  transition: all .3s;
  
  &:before {
    position: relative;
    top: -3px;
    left: 5px;
    content: "?";
    font-size: .85em;
    color: ${props => props.theme.global.colors["dark-3"]};
    font-weight: 900;
    transition: all .3s;
  }

  &:hover {
    background: ${props => props.theme.global.colors["brand-3"]};

    &:before {
      color: white;
    }
  }
`

export default PriceDescription