import React from 'react'
import styled from 'styled-components'
import { DocsLayout } from '../../../components'
import { DocsAside, DocsContent, DocsNav, DocsSearchBar, DocsTitle } from '../index'
import { Anchor, Box } from 'grommet'
import { OfflineStorage } from 'grommet-icons'

const DocsPage = ({ location, pageContext, contentRef }) => {
  const { categories, page, pages, menuLinks } = pageContext
  const { title, description } = page.post.frontmatter
  const data = pageContext

  return (
    <DocsLayout 
      location={location} 
      data={page}
      title={title}
      metaDescription={description}
    >
      <DocsNav categoryData={categories} pageData={pages} menuLinks={menuLinks} />
      <Box 
        as="section"
        gridArea="main"
        direction="column" 
        pad={{
          left: "small",
          right: "6em"
        }} 
      >
        <ContentContainer>
          <SearchContainer 
            direction="row" 
            align="center" 
            background="white"
            margin={{ left: "medium" }}
            pad={{ top: "small", bottom: "14px" }}
          >
            <Box basis="3/4" pad={{ right: "large" }}>
              <DocsSearchBar data={data} />
            </Box>
            <Box basis="1/4" align="start">
              <ConsoleLink label="Launch Console" icon={<OfflineStorage />} reverse href="https://console.hippobyte.com/" />
            </Box>
          </SearchContainer>
          <DocsTitle
            title={title}
            description={description}
          />
          <Box
            flex
            direction="row"
            margin={{ left: "medium" }}
          >
            <DocsContent 
              data={page}
              ref={contentRef}
            />
            <DocsAside 
              data={page}
              location={location}
              contentRef={contentRef}
            />
          </Box>
        </ContentContainer>
      </Box>
    </DocsLayout>
  )
}

const ContentContainer = styled(Box)`
  position: relative;
`

const SearchContainer = styled(Box)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
`

const ConsoleLink = styled(Anchor)`
  font-weight: 600;
  font-size: .955em;
`

export default DocsPage