import React from 'react'
import { ResponsiveContext, ThemeContext } from 'grommet'
import { Menu } from '../..'

const Header = (props) => {
  return (
    <ResponsiveContext.Consumer>
      {
        screenSize => {
          return (
            <Menu 
              screenSize={screenSize}
              {...props}
            />
          )
        }
      }
    </ResponsiveContext.Consumer>
  )
}

export default Header