import React from 'react'
import styled from 'styled-components'
import { Box, Text } from 'grommet'
import { Next, Previous } from 'grommet-icons'
import { AnchorLink } from '../../index'
import { Copyright } from '../../../components'

const DocsFooter = ({ next, previous }) => (
  <Box>
    <Box direction="row" margin={{ top: "large", bottom: "xlarge" }}>
      <NavigationLink data={previous} />
      <NavigationLink next data={next} />
    </Box>
    <Box margin={{ bottom: "large" }}>
      <Questions />
      <Copyright 
        noticeOnly={true}
        background="transparent"
        pad="0"
        color="dark-2"
        weight={300}
      />
    </Box>
  </Box>
)

const Questions = () => {
  return (
    <Box margin={{ bottom: "large" }} round="xsmall" pad="small" border={{ side: "all", color: "brand-6" }} background="light-0">
      <Text weight={500} size="15px" color="dark-5"><strong>Have Questions?</strong> <a href="mailto:support@hippobyte.com">Reach out</a>, we are happy to address any questions you may have.</Text>
    </Box>
  )
}

const NavigationLink = ({ next, data }) => {
  const NavIcon = ({ next }) => (
    next ? <Next color="plain" /> : <Previous color="plain" />
  )

  const NavText = ({ next }) => {
    const direction = next ? "Next" : "Previous"
    return (
      <Box>
        <Direction align={next && "end"}>{direction}</Direction>
        <Box>{data.frontmatter.title}</Box>
      </Box>
    )
  }

  return (
    <Box basis="1/2" align={next && "end"}>
      {
        data && (
          <StyledAnchorLink 
            path={data.fields.slug}
            icon={<NavIcon next={next} />} 
            label={<NavText next={next} />} 
            a11yTitle={`Previous entry:`}
            reverse={next}
          />
        )
      }
    </Box>
  )
}

const StyledAnchorLink = styled(AnchorLink)`
  color: ${props => props.theme.global.colors["dark-2"]};
  font-size: 1em;
  line-height: 1.225em;
  transition: color .3s;

  &:hover {
    color: ${props => props.theme.global.colors["dark-4"]};
  }
`

const Direction = styled(Box)`
  font-weight: 400;
  font-size: .645em;
  text-transform: uppercase;
  letter-spacing: .1em;
`

export default DocsFooter