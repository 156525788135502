import React, { useState, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box, Text, TextInput } from 'grommet'
import { useClickAway, useKey } from 'react-use'

const DocsSearchBar = ({ data }) => {
  const [suggestionOpen, setSuggestionOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [, updateState] = useState()  
  const forceUpdate = useCallback(() => updateState({}), [])
  const [resultQuery, setResultQuery] = useState([])
  const searchContainerRef = useRef()
  const graphData = data.pages

  useEffect(() => {
    forceUpdate()
  }, [forceUpdate])

  const handleOpen = useCallback(() => setSuggestionOpen(true), [
    setSuggestionOpen
  ])

  const handleClose = useCallback(() => setSuggestionOpen(false), [
    setSuggestionOpen
  ])

  const handleChange = (event) => {
    const { value: newSearchTerm } = event.target;
    setSearchTerm(newSearchTerm)

    if (!newSearchTerm.trim()) {
      setResultQuery([])
      handleClose()
    } else {
      handleOpen()
      setResultQuery(graphData)
    }
  }

  useKey('Escape', handleClose)
  useClickAway(searchContainerRef, handleClose)

  const Empty = () => (
    <SearchResultItem
      direction="column"
      align="center"
      pad={{ vertical: "xlarge", horizontal: "medium" }}
      isEmpty
    >
      <SearchResultsExcerpt
        truncate={true}
        wordBreak="break-all"
      >
        No results found for query <strong>{searchTerm}</strong>
      </SearchResultsExcerpt>
    </SearchResultItem>
  )

  const SearchSuggestion = () => {
    const results = resultQuery.filter(item => item.post.excerpt.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)
    if (results.length > 0) {
      return results.map(
        (result, index) => (
          <SearchResultItemLink to={result.post.fields.slug}>
            <SearchResultItem
              direction="column" 
              pad={{ vertical: "small", left: "medium", right: "large" }}
              border={index < results.length - 1 ? { side: "bottom", size: "xsmall", color: "light-4" } : undefined}
            >
              <SearchResultsCategory>
                {result.post.frontmatter.category}
              </SearchResultsCategory>
              <SearchResultsHeading>
                {result.post.frontmatter.title}
              </SearchResultsHeading>
              <SearchResultsDescription 
                truncate={true}
                wordBreak="break-all"
              >
                {result.post.frontmatter.description}
              </SearchResultsDescription>
              <SearchResultsExcerpt
                truncate={true}
                wordBreak="break-all"
              >
                {result.post.excerpt}
              </SearchResultsExcerpt>
            </SearchResultItem> 
          </SearchResultItemLink>
        )
      )
    }
    return <Empty />
  }

  return (
    <>
      <Backdrop suggestionOpen={suggestionOpen} />
      <SearchContainer ref={searchContainerRef}>
        <SearchInput
          type="search"
          placeholder="Search Hipponbyte Docs"
          value={searchTerm}
          onChange={handleChange}
        />
        {
          suggestionOpen && (
            <SearchSuggestionsContainer>
              <SearchSuggestionsList>
                <SearchSuggestion />
              </SearchSuggestionsList>
            </SearchSuggestionsContainer>
          )
        }
      </SearchContainer>
    </>
  )
}

const SearchInput = styled(TextInput)`
  position: relative;
  border: 1px solid ${props => props.theme.global.colors["light-5"]};
  background: ${props => props.theme.global.colors.white};
  height: 42px;
  transition: border .3s;

  &:focus {
    color: ${props => props.theme.global.colors.black};
    border: 1px solid ${props => props.theme.global.colors.brand};
  }
`

const Backdrop = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: ${props => props.suggestionOpen ? 1 : 0};
  visibility: ${props => props.suggestionOpen ? 'visible' : 'hidden'};
  background-color: rgba(90,98,112,0.5);
  transition-property: opacity, visibility;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  z-index: 1;
`

const SearchContainer = styled.div`
  position: relative;
  z-index: 2;
`

const SearchSuggestionsContainer = styled(Box)`
  position: absolute;
  top: 100%;
  left: 0px;
  right: auto;
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: auto;
  margin-top: 14px;
  border-radius: 4px;
  border: 1px solid ${props => !props.isEmpty && props.theme.global.colors.brand};
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 12px;
`

const SearchSuggestionsList = styled.div`
  max-height: calc(100vh - 72px - 32px);
  border-radius: inherit;
  overflow: auto;
  background: ${props => props.theme.global.colors.white};
  position: relative;
`

const SearchResultItem = styled(Box)`
  min-height: initial;
  transition: background .2s;

  &:hover {
    background: ${props => !props.isEmpty && props.theme.global.colors["light-1"]};
  }
`

const SearchResultItemLink = styled(Link)`
  &:hover {
    text-description: none;
  }
`

const SearchResultsCategory = styled(Text)`
  display: block;
  color: ${props => props.theme.global.colors["dark-4"]};
  font-weight: 700;
  text-transform: uppercase;
  font-size: .785em;
  letter-spacing: 1px;
`

const SearchResultsHeading = styled(Text)`
  display: block;
  color: ${props => props.theme.global.colors["dark-2"]};
  font-weight: 700;
  font-size: 1.25em;
  margin-top: .325em;
  letter-spacing: 0.3px;
`

const SearchResultsDescription = styled(Text)`
  display: block;
  color: ${props => props.theme.global.colors["dark-2"]};
  font-weight: 500;
  font-size: 1.125em;
  margin-top: .325em;
  max-width: auto;
`

const SearchResultsExcerpt = styled(Text)`
  display: block;
  color: ${props => props.theme.global.colors["dark-2"]};
  font-weight: 500;
  font-size: .850em;
  max-width: auto;
`

export default DocsSearchBar