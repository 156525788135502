import React, { useState } from 'react'
import { RangeInput } from 'grommet'

const RangeSlider = ({ min, max, steps, onChange, ...rest }) => {
  const [value, setValue] = useState(0)
  const [points, sliderTransform] = scaleTransform(min, max, steps)

  const onChangeHandler = (e) => {
    setValue(e.target.value)
    onChange && onChange({
      value: value,
      transformedValue: sliderTransform(value)
    })
  }

  return (
    <RangeInput
      value={value}
      onChange={onChangeHandler}
      min={0}
      max={points}
      {...rest}
    />
  )
}

export default RangeSlider

//  "exponential step" curry function
//   return tuple where 
//   [0] is the number of inputs we need our slider to have
//   [1] is our output transform function
function scaleTransform(min, max, intervals) {

  //determine how many "points" we need
  let distributions = intervals.length
  let descretePoints = Math.ceil(
    (max - min) / intervals.reduce((total, step) => total + step / distributions, 0)
  )

  return [
    descretePoints,
    (input) => {
      let stepTransforms = intervals.map((s, i) => {
        let setCount = Math.min(Math.ceil(input - (descretePoints * i / distributions)), Math.round(descretePoints / distributions))
        return setCount > 0 ? setCount * s : 0
      })

      let lastStep = 0;
      let out = Math.round(stepTransforms.reduce((total, num, i) => {
        if (num) {
          lastStep = i
        }
        return total + num
      })) + min

      let currentUnit = intervals[lastStep];
      return Math.min(
        Math.round((out / currentUnit)) * currentUnit,  //round to nearest step
        max
      )
    }
  ]
}