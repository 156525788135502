import React from 'react'
import { Heading, Text } from 'grommet'

const ContainerHeader = ({ title, subTitle, align, size, margin }) => {

  const Title = () => {
    const fontSize = size === "large" ? "4em" : "2.6em"
    const marginBottom = align === "center" ? "small" : "xxsmall"
    if (title) {
      return (
        <Heading 
          level={3}
          size={fontSize}
          color="black" 
          margin={{ ...{ top: "xlarge", bottom: marginBottom, horizontal: "11vw" }, ...margin}} 
          alignSelf={align}
          textAlign={align}
          style={{ fontWeight: 400, maxWidth: "100%", fontFamily: "'Barlow Semi Condensed', sans-serif" }}
        >
          {title}
        </Heading>
      )
    } else {
      return null
    }
  }

  const SubTitle = () => {
    const fontSize = size === "large" ? "xlarge" : "large"
    if (subTitle) {
      return (
        <Text 
          weight={300} 
          size={fontSize}
          color="dark-5"
          alignSelf={align}
          textAlign={align}
          margin={{ ...{ horizontal: "11vw" }, ...margin }}
        >
          {subTitle}
        </Text>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <Title />
      <SubTitle />
    </>
  )
}

export default ContainerHeader