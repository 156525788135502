import React from 'react'
import { Box } from 'grommet'

const Shape = (props) => {
  const { type, rotate, size, position, overflow, style, ...rest } = props

  const options = () => {
    switch(type) {
      case "circle":
        return { round: "50%" }
      case "rectangle":
        return { width: "75%" }
      default:
        return {}
    }
  }

  const sizer = () => {
    switch(type) {
      case "rectangle":
        return { width: "70%", height: size }
      default:
        return { width: size, height: size}
    }
  }

  return (
    <Box
      width={size}
      height={size}
      a11yTitle={type}
      style={{ 
        position: "absolute",
        overflow: overflow,
        ...position,
      }}
    >
      <Box
        {...options()}
        {...sizer()}
        style={{
          opacity: "0.8", 
          transform: `rotate(${rotate}deg`, 
          transformOrigin: `calc(${size}/2)`,
          ...style
        }}
        {...rest}
      />
    </Box>
  )
}

Shape.defaultProps = {
  size: "xsmall",
  rotate: 0,
  overflow: "visible"
}

export default Shape