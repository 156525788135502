import React from 'react'
import { createBreakpoint } from 'react-use'
import { Box } from 'grommet'
import { tagger } from '../../../utils'
import { Article } from '../../../components'
import { BlogLayout } from '../..'

const useBreakpoint = createBreakpoint({ small: 0, medium: 769, large: 1200 })

const BlogIndex = ({ location, data, topic, titleTemplate }) => {
  const tagValues = data.tags && data.tags.edges && data.tags.edges.map(post => post.node && post.node.frontmatter.tags).reduce((total, currentValue) => total.concat(currentValue), []).filter((v, i, a) => v && a.indexOf(v) === i)
  const tags = tagValues && tagger(tagValues, "/articles/topics/")
  const posts = data.posts.edges
  const breakpoint = useBreakpoint()

  const dynamicBasis = () => {
    switch(breakpoint) {
      case "small":
        return "full"
      case "medium":
        return "48.25%"
      default:
        return "32.25%"
    }
  }

  const title = topic ? `${topic} Articles` : `Learn how to leverage data in your enterprise`
  const description = topic ? `Articles and blog entries focused on ${topic}. Learn how to maximize the value of your enterprise data to deliver actionable insights.` : `Recent thoughts from the Hippobyte blog focused on how to leverage automated ETL connectors to deliver better business analytics within your enterprise.`

  return (
    <BlogLayout 
      location={location} 
      title={title}
      description={description}
      titleTemplate={titleTemplate}
      tags={tags}
      topic={topic}
    >
      <Box 
        as="section"
        flex={false}
        fill="horizontal"
        margin={{ vertical: "2.5em" }}
      >
        <Box 
          direction="row-responsive"
          justify={posts && posts.length === 2 ? "start" : "between"}
          wrap={true}
        >
        {
          posts && posts.map(post => {
            const { author, header, title, description, featuredpost, featuredimage } = post.node.frontmatter
            const featuredImageFluid = featuredimage && featuredimage.childImageSharp.fluid
            const { slug } = post.node.fields
            const margin = posts && posts.length === 2 ? { right: "medium" } : {}
            return (
              <Article
                basis={dynamicBasis()}
                header={header}
                author={author}
                title={title}
                description={description}
                featuredpost={featuredpost}
                featuredImage={featuredImageFluid}
                path={slug}
                margin={margin}
              />
            )
          })
        }
        </Box>
      </Box>
    </BlogLayout>
  )
}

BlogIndex.defaultProps = {
  titleTemplate: "Hippobyte"
}

export default BlogIndex