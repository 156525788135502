import React, { useState } from 'react'
import styled from 'styled-components'
import { Accordion, AccordionPanel, Anchor, Box, Text } from 'grommet'
import { Link } from 'gatsby'
import * as grommetIcons from 'grommet-icons'
import { MenuItemContainer, MenuItemHeader } from '../index'
import { AnchorLink } from '../..'

const MenuItem = ({ screenSize, theme, label, items, path, margin, type }) => {
  const [hovering, setHovering] = useState([])

  const mouseEnter = (item) => setHovering([item])
  const mouseLeave = (item) => setHovering(hovering.filter(h => h !== item))
  const isHovering = (item) => hovering.filter(h => h === item).length > 0

  const Icon = (props) => {
    const { type, ...rest } = props
    const GrommetIcon = grommetIcons[type]
    return type && GrommetIcon ? <GrommetIcon {...rest} /> : null
  }

  const calculatePadding = () => {
    if (type == "action") {
      return "0"
    }

    if (screenSize === "small") {
      return "0"
    }

    if (screenSize === "medium") {
      return "8px 12px 10px"
    }

    return "8px 16px 10px"
  }

  const menuItemPadding = calculatePadding()

  if (screenSize === "small") {
    return (
      <Accordion>
        <AccordionPanel
          margin={{ vertical: "medium" }}
          label={
            items ? (
              <Text weight={700} color="dark-4">{label}</Text>
            ) : (
              <Box fill>
                <AnchorLink 
                  path={path}
                  label={<Text weight={700} color="dark-4">{label}</Text>}
                />
              </Box>
            )
          }
        >
        {
          items && (
            <Box pad={{ bottom: "medium" }}>
              {
                items.map((menu, index) => (
                  <Link key={index} to={menu.path}>
                    {
                      menu.componentLabel && <>{menu.componentLabel}</>
                    }
                    {
                      menu.label && (
                        <MenuItemContainer pad={{ vertical: "14px" }}>
                          <MenuItemHeader 
                            label={menu.label} 
                            description={menu.description}
                            icon={<Icon type={menu.icon} size="22px" color="dark-4" />}
                          />
                        </MenuItemContainer>
                      )
                    }
                  </Link>
                ))
              }
            </Box>
          )
        }
        </AccordionPanel>
      </Accordion>
    )
  }

  return (
    <Box
      align="center"
      style={{
        position: "relative",
        cursor: "pointer"
      }}
    >
      <Box
        as={path ? AnchorLink : Anchor}
        onMouseEnter={() => mouseEnter(label)}
        onMouseLeave={() => mouseLeave(label)}
        path={path}
      >
        <Box
          flex="grow"
          margin={margin && margin}
          pad={{
            bottom: "14px"
          }}
          style={{
            position: "relative",
            top: "8px"
          }}
        >
          <MenuLabel
            className={isHovering(label) && type === "default" && "active"}
            size={screenSize == "small" ? "small" : screenSize === "medium" && "16px"}
            weight={screenSize === "small" ? 700 : 600}
            color={theme === "light" && "#F6F7FD"}
            activeColor={theme === "light" && "#d3d0dc"}
            activeBackground={theme === "light" && "#ffffff26"}
            screenSize={screenSize}
            padding={menuItemPadding}
            type={type}
          >
            {label}
            { 
              items && <Icon type="Down" color={theme === "light" && "#F6F7FD"} size="12px" style={{ marginLeft: "0.5em" }} />
            }
          </MenuLabel>
        </Box>
      </Box>
      {
        items && (
          <SubMenuContainer 
            hovering={isHovering(label)}
            onMouseEnter={() => mouseEnter(label)}
            onMouseLeave={() => mouseLeave(label)}
            elevation="large"
            animation={{
              "type": "slideDown",
              "delay": 0,
              "duration": 300,
              "size": "small"
            }}
          >
            {
              items && items.map((menu, index) => (
                <Link key={index} to={menu.path}>
                  {
                    menu.componentLabel && <>{menu.componentLabel}</>
                  }
                  {
                    menu.label && (
                      <MenuItemContainer pad={{ horizontal: "24px", vertical: "14px" }} width="500px">
                        <MenuItemHeader 
                          label={menu.label} 
                          description={menu.description}
                          icon={<Icon type={menu.icon} size="22px" color="dark-4" />}
                        />
                      </MenuItemContainer>
                    )
                  }
                </Link>
              ))
            }
          </SubMenuContainer>
        )
      }
    </Box>
  )
}

MenuItem.defaultProps = {
  type: "default"
}

const SubMenuContainer = styled(Box)`
  position: absolute;
  top: 60px;
  left: 0;
  border: 1px solid ${props => props.theme.global.colors["light-2"]};
  background: rgb(255, 255, 255, 0.98);
  padding: 15px 0;
  border-radius: 6px;
  display: ${props => props.hovering ? "block" : "none"};
  min-width: 400px;
  z-index: 1000;
`

const MenuLabel = styled(Text)`
  color: ${props => props.color ? props.color : "black"};
  border-radius: 8px;
  transition: all 0.300s;
  letter-spacing: 0.6px;
  padding: ${props => props.padding};

  &.active {
    color: ${props => props.activeColor ? props.activeColor : props.theme.global.colors["brand"]};
    background: ${props => props.activeBackground ? props.activeBackground : props.theme.global.colors["brand"]}0d;

    & > svg {
      fill: ${props => props.activeColor ? props.activeColor : props.theme.global.colors["brand"]};
      stroke: ${props => props.activeColor ? props.activeColor : props.theme.global.colors["brand"]};
    }
  }
`

export default MenuItem