import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Box } from 'grommet'
import { Article } from '../../../components'
import { PageTitle } from '../../../components/layout'

const BlogRecentCard = ({ title, articles, ...rest }) => {
  const defaultArticles = useStaticQuery(graphql`
    query FeaturedPostsQuery {
      posts: allMarkdownRemark(
        filter: {frontmatter: {templateKey: {eq: "blog"}}}, 
        sort: {fields: [frontmatter___order, frontmatter___date], 
        order: [ASC, DESC]},
        limit: 3
      ) {
        edges {
          node {
            id
            timeToRead
            frontmatter {
              header
              title
              description
              date
              relativeDate: date(fromNow: true)
              featuredimage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              featuredpost
              tags
            }
            fields {
              slug
            }
            excerpt(pruneLength: 160)
            html
          }
        }
      }
    }  
  `)

  const posts = articles || defaultArticles.posts.edges
  
  const Entry = (props) => {
    const { node } = props
    const { frontmatter } = node
    const { header, title, description, featuredimage } = frontmatter
    const featuredImageFluid = featuredimage && featuredimage.childImageSharp.fluid
    const { slug } = node.fields

    return (
      <Article
        header={header}
        title={title}
        description={description}
        featuredImage={featuredImageFluid}
        path={slug}
      />
    )
  }

  if (posts.length > 0) {
    return (
      <Box
        margin={{ horizontal: "11vw", bottom: "xlarge" }}
      >
        { title && <PageTitle label={title} style={{ fontSize: "2.2em", fontWeight: 600 }} /> }
        <Box 
          direction="row-responsive"
          gap="medium"
          {...rest}
        >
        {
          posts.map((item, index) => (
            <Entry 
              key={index} 
              index={index}
              {...item}
            />
          ))
        }
        </Box>
      </Box>
    )
  } else {
    return null
  }
}

export default BlogRecentCard