import React from 'react'
import { Box } from 'grommet'

const BlogFooter = () => (
  <Box>
    <Box direction="row" margin={{ top: "large", bottom: "xlarge" }}>
    </Box>
  </Box>
)

export default BlogFooter