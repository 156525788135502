import React from 'react'
import { AnchorLink, BlogLayout } from '../../../components'
import { BlogMarkdown, BlogPostPreview, BlogFooter } from '../index'
import { tagger } from '../../../utils'
import { Box } from 'grommet'
import { FormPreviousLink } from 'grommet-icons'

const BlogPage = ({ location, data }) => {
  const tagValues = data.tags && data.tags.edges && data.tags.edges.map(post => post.node && post.node.frontmatter.tags).reduce((total, currentValue) => total.concat(currentValue), []).filter((v, i, a) => v && a.indexOf(v) === i)
  const tags = tagValues && tagger(tagValues, "/articles/topics/")
  const { author, date, title, description, featuredpost, featuredimage } = data.post.frontmatter
  const { slug } = data.post.fields

  return (
    <BlogLayout 
      location={location} 
      tags={tags}
      title={title}
      description={description}
      showHeading={false}
    >
      <Box 
        as="section"
        gridArea="main"
        direction="column"
        width="large"
      >
        <AnchorLink 
          label="Back to Articles" 
          path="/articles" 
          icon={<FormPreviousLink />} 
          margin={{ top: "medium", bottom: "large" }}
        />
        <BlogPostPreview 
          author={author}
          date={date}
          title={title}
          description={description}
          featuredpost={featuredpost}
          featuredimage={featuredimage}
          slug={slug}
          readMoreLink={false}
        />
        <BlogMarkdown dangerouslySetInnerHTML={{ __html: data.post.html }} />
        <AnchorLink 
          label="Back to Articles" 
          path="/articles" 
          icon={<FormPreviousLink />} 
          margin={{ top: "medium", bottom: "large" }}
        />
        <BlogFooter />
      </Box>
    </BlogLayout>
  )
}

export default BlogPage