import React from 'react'
import { createBreakpoint } from 'react-use'
import { Box } from 'grommet'
import { slugger } from '../../../utils'
import { Article } from '../..'
import { ContainerHeader } from '../../container'

const useBreakpoint = createBreakpoint({ small: 0, medium: 769, large: 1200 })

const ConnectorList = ({ title, description, connectors, pathPrefix, pathSuffix }) => {
  const breakpoint = useBreakpoint()

  const dynamicBasis = () => {
    switch(breakpoint) {
      case "small":
        return "48.25%"
      case "medium":
        return "48.25%"
      default:
        return "23.75%"
    }
  }

  const dynamicPad = () => {
    switch(breakpoint) {
      case "medium":
      case "large":
        return "small"
      default:
        return "large"
    }
  }

  const pathGenerator = (title) => {
    if (pathPrefix && pathSuffix) {
      return slugger([...pathPrefix, title, ...pathSuffix])
    } else if (pathPrefix) {
      return slugger([...pathPrefix, title])
    } else if (pathSuffix) {
      return slugger([title, ...pathSuffix])
    } else {
      return slugger([title])
    }
  }

  return (
    <>
      <ContainerHeader 
        title={title} 
        subTitle={description}
        margin={{ vertical: 0, horizontal: 0 }}
      />
      <Box 
        direction="row-responsive"
        margin={{ top: "large" }}
        justify={connectors && connectors.length % 4 > 0 ? "start" : "between"}
        wrap={true}
      >
        {
          connectors && connectors.map(connector => {
            const { title, category, featuredimage } = connector
            const productImage = featuredimage && featuredimage.childImageSharp && featuredimage.childImageSharp.fluid
            const margin = connectors && connectors.length % 4 > 0 ? { right: "1.25%" } : {}
            return (
              <Article
                basis={dynamicBasis()}
                pad={dynamicPad()}
                header={category}
                title={title}
                productImage={productImage}
                margin={margin}
                path={pathGenerator(title)}
                connector={true}
              />
            )
          })
        }
      </Box>
    </>
  )
}

export default ConnectorList