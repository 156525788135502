import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box, Text } from 'grommet'
import { Close } from 'grommet-icons'
import { useOptions } from '../../../state/hooks'

const CookieBar = () => {
  const { options, setPrivacyStatus } = useOptions()

  useEffect(() => {
    const timestamp = Math.round(new Date().getTime() / 1000)
    const acceptedOn = localStorage.getItem("cookie-use-disclaimer-accepted-on")
    const acceptedTime = acceptedOn ? Date.parse(acceptedOn) / 1000 : 0
    const accepted = localStorage.getItem("cookie-use-disclaimer-accepted") === "true"

    // reset acceptance if over 14 days since last accepted
    if (timestamp - acceptedTime > 1209600) {
      resetAcceptance()
      return 
    }

    // set acceptance state if in localStorage
    if (accepted) { 
      setPrivacyStatus(true)
      return
    }
  }, [])

  const setAcceptance = () => {
    localStorage.setItem("cookie-use-disclaimer-accepted", "true")
    localStorage.setItem("cookie-use-disclaimer-accepted-on", new Date())
    setPrivacyStatus(true)
  }

  const resetAcceptance = () => {
    localStorage.removeItem("cookie-use-disclaimer-accepted")
    localStorage.removeItem("cookie-use-disclaimer-accepted-on")
    setPrivacyStatus(false)
  }

  const handleClick = () => {
    setAcceptance()
  }

  return null

  return (
    <>
    {
      !options.privacyAccepted && (
        <Container 
          direction="row"
          justify="center" 
          width="full"
          background="light-2" 
          align="center" 
          pad={{ vertical: "8px" }} 
          animation={{
            type: "slideUp",
            delay: 1400,
            duration: 900,
            size: "xlarge"
          }}
        >
          <Text color="dark-1" size="small">By continuing to use this site you consent to the use of cookies in accordance with our <Link to="/terms/cookies" style={{color: "inherit"}}>cookie policy</Link>.</Text>
          <Close size="small" style={{position: "relative", top: "2px", cursor: "pointer", marginLeft: ".4em"}} onClick={handleClick} /> 
        </Container>
      )
    }
    </>
  )
}

const Container = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;

  @media print {
    display: none;
  }
`

export default CookieBar