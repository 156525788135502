import React from 'react'
import styled from 'styled-components'

const Dots = ({ style }) => {
  return (
    <Container width="554" height="461" viewBox="0 0 554 461" fill="none" style={style} xmlns="http://www.w3.org/2000/svg">
      <circle cx="422.455" cy="20.2727" r="20" fill="#3FA9F5"/>
      <circle cx="20" cy="121" r="20" fill="#dbe54b"/>
      <circle cx="242" cy="421" r="40" transform="rotate(-90 242 421)" fill="#4C5FE850"/>
      <mask id="mask0" maskUnits="userSpaceOnUse" x="202" y="381" width="80" height="80">
        <circle cx="242" cy="421" r="40" transform="rotate(-90 242 421)" fill="#2DE3DA"/>
      </mask>
      <g mask="url(#mask0)">
          <rect width="118.855" height="1.49503" transform="matrix(-0.707107 0.707107 0.707107 0.707107 264.022 298.063)" fill="white"/>
          <rect width="118.855" height="1.49503" transform="matrix(-0.707107 0.707107 0.707107 0.707107 270.365 304.406)" fill="white"/>
          <rect width="118.855" height="1.49503" transform="matrix(-0.707107 0.707107 0.707107 0.707107 267.194 301.235)" fill="white"/>
          <rect width="118.855" height="1.49503" transform="matrix(-0.707107 0.707107 0.707107 0.707107 276.708 310.749)" fill="white"/>
          <rect width="118.855" height="1.49504" transform="matrix(-0.707107 0.707107 0.707107 0.707107 273.537 307.578)" fill="white"/>
          <rect width="118.855" height="1.49503" transform="matrix(-0.707107 0.707107 0.707107 0.707107 283.051 317.092)" fill="white"/>
          <rect width="118.855" height="1.49503" transform="matrix(-0.707107 0.707107 0.707107 0.707107 279.88 313.921)"  fill="white"/>
          <rect width="118.855" height="1.49503" transform="matrix(-0.707107 0.707107 0.707107 0.707107 289.394 323.435)" fill="white"/>
          <rect width="118.855" height="1.49503" transform="matrix(-0.707107 0.707107 0.707107 0.707107 286.222 320.263)" fill="white"/>
          <rect width="118.855" height="1.49503" transform="matrix(-0.707107 0.707107 0.707107 0.707107 295.737 329.778)" fill="white"/>
          <rect width="118.855" height="1.49503" transform="matrix(-0.707107 0.707107 0.707107 0.707107 292.565 326.606)" fill="white"/>
          <rect width="118.855" height="1.49503" transform="matrix(-0.707107 0.707107 0.707107 0.707107 302.079 336.121)" fill="white"/>
          <rect width="118.855" height="1.49503" transform="matrix(-0.707107 0.707107 0.707107 0.707107 298.908 332.949)" fill="white"/>
          <rect width="118.855" height="1.49503" transform="matrix(-0.707107 0.707107 0.707107 0.707107 308.422 342.463)" fill="white"/>
          <rect width="118.855" height="1.49503" transform="matrix(-0.707107 0.707107 0.707107 0.707107 305.251 339.292)" fill="white"/>
      </g>
      <circle cx="530.511" cy="355.5" r="41.0455" fill="#a5d8ff"/>
      <circle cx="306.592" cy="92.4074" r="13.0382" fill="#d0e54b"/>
      <circle cx="339.779" cy="59.2228" fill="#BC027F" r="13.0382"/>
      <circle cx="207.037" cy="59.2214" fill="#4C5FE8" r="13.0368"/>
      <circle cx="306.591" cy="26.0368" fill="#B17ACC" r="13.0368"/>
      <circle cx="339.777" cy="158.779" fill="#59C156" r="13.0382"/>
      <circle cx="306.592" cy="125.592" fill="#FB8400" r="13.0382"/>
      <circle cx="339.777" cy="125.592" fill="#4C5FE8" r="13.0382"/>
      <circle cx="240.221" cy="125.591" fill="#cd07f4" r="13.0368"/>
      <circle cx="274.593" cy="92.4085" r="13.0382" fill="#FFD280"/>
      <circle transform="matrix(1 -8.74227e-08 -8.74229e-08 -1 472.518 125.593)" fill="#d0e54b" r="13.0382"/>
      <circle transform="matrix(1 -8.74229e-08 -8.74226e-08 -1 505.702 158.777)" fill="#4be5aa" r="13.0382"/>
      <circle transform="matrix(1 -8.74228e-08 -8.74227e-08 -1 372.963 158.779)" fill="#4C5FE8" r="13.0368"/>
      <circle transform="matrix(1 -8.74227e-08 -8.74228e-08 -1 472.517 191.963)" fill="#F2C4E3" r="13.0368"/>
      <circle transform="matrix(1 -8.74229e-08 -8.74226e-08 -1 505.702 59.2234)" fill="#FF5A54" r="13.0382"/>
      <circle r="13.0382" transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 439.334 92.408)" fill="#A1DA9E"/>
      <circle transform="matrix(1 -8.74229e-08 -8.74226e-08 -1 505.702 92.408)" fill="#CCFFFC" r="13.0382"/>
      <circle transform="matrix(1 -8.74228e-08 -8.74227e-08 -1 406.147 92.4094)" fill="#4C5FE8" r="13.0368"/>
      <circle transform="matrix(1 -8.74227e-08 -8.74228e-08 -1 439.334 125.593)" fill="#FB8400" r="13.0382"/>
    </Container>
  )
}

const Container = styled.svg(props => ({
  position: "absolute",
  top: "17vh",
  right: "0",
  width: "36%",
  zIindex: "-3",
  ...props.style
}))

export default Dots