import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { Box, Text } from 'grommet'
import { PageLayout } from '../../../components'

const LegalPage = ({ location, data }) => {
  const { title, date, html } = data
  const formattedDate = moment(date).format('ll')

  return (
    <PageLayout 
      location={location}
      title={title}
      pageTheme="dark"
      pageHeading={{
        title: {
          label: title
        }
      }}
      breadcrumbs={{
        data: [
          { label: "Legal & Security", path: "/legal" },
          { label: title }
        ]
      }}
      footer={true}
    >
      <Box margin={{ horizontal: "11vw", bottom: "xlarge" }}>
        {
          date && (
            <Box 
              round="xsmall" 
              pad="small"
              margin={{ bottom: "large" }}
              background="light-1"
            >
              <Text color="black" size="1em" weight={700}>
                Last updated at: <Text size="0.95em" weight={500}>{formattedDate}</Text>
              </Text>
            </Box>
          )
        }
        <PageContent dangerouslySetInnerHTML={{ __html: html }} />
      </Box>
    </PageLayout>
  )
}

const PageContent = styled(Box)(props => ({
  h2: {
    fontSize: '1.5em',
    lineHeight: '1em',
    fontWeight: '700',
    marginTop: '0.1em',
    marginBottom: '1em',
    color: props.theme.global.colors.black,
  },
  h3: {
    fontSize: '1.45em',
    fontWeight: '400',
  },
  h4: {
    fontSize: '1.25em',
    fontWeight: '400',
  },
  h5: {
    fontSize: '1.055em',
    fontWeight: '400',
  },
  h6: {
    fontSize: '1.055em',
    fontWeight: '600',
    marginBottom: '0.5em',
  },
  em: {
    background: props.theme.global.colors["light-2"],
    fontStyle: 'normal',
    fontWeight: '500',
    color: props.theme.global.colors["accent-2"],
    paddingLeft: '6px',
    paddingRight: '6px',
    letterSpacing: '0.4px',
    borderRadius: '4px'
  },
  [['ul', 'ol']]: {
    marginLeft: '1.45em',
    marginTop: '0',
    marginBottom: '1.1em',
    'li:last-child': {
      marginBottom: '0.5em'
    }
  },
  [['p', 'li']]: {
    fontSize: '1em',
    lineHeight: '1.6em'
  },
  li: {
    marginBottom: '.725em'
  },
  p: {
    display: 'block',
    marginTop: '0',
    marginBottom: '1.1em'
  },
  // style all anchors with an href and no prior classesh2
  // this helps avoid anchors with names and styled buttons
  'a[href]:not([class])': {
    color: props.theme.global.colors.brand,
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline'
    },
    code: {
      color: 'inherit'
    }
  },
  [['h1', 'h2', 'h3', 'h4', 'h5', 'h6']]: {
    'a > svg': {
      display: 'none'
    },
    fontFamily: '"Muli", sans-serif',
    fontStyle: 'initial',
    marginTop: '0px',
    textTransform: "initial",
    ':not(:hover) a svg': {
      visibility: 'hidden'
    },
    code: {
      whiteSpace: 'normal'
    },
    'a.anchor': {
      ':hover': {
        opacity: '.9'
      },
      svg: {
        fill: props.theme.global.colors.brand
      },
      '&.before': {
        top: 'auto'
      }
    }
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    margin: '0 auto'
  },
  '.mermaid svg': {
    maxWidth: '100%'
  },
  blockquote: {
    margin: '0px',
    borderLeftWidth: '3px',
    borderLeftColor: props.theme.global.colors.brand,
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '20px',
    paddingRight: '12px',
    marginBottom: '32px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    background: props.theme.global.colors['light-1'],
    p: {
      color: props.theme.global.colors['dark-1'],
      fontSize: '0.9em',
      lineHeight: '1.4em',
      fontStyle: 'normal'
    }
  },
  table: {
    overflow: 'hidden',
    marginBottom: '1.5em',
    borderColor: props.theme.global.colors["light-3"],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '6px',
    borderSpacing: '1px',
    thead: {
      color: props.theme.global.colors.white,
      background: props.theme.global.colors.brand,
    },
    'thead > tr > th': {
      fontSize: '0.9em',
      letterSpacing: '0.6px',
      borderColor: props.theme.global.colors.brand,
      borderWidth: '3px',
      borderStyle: 'solid',
    },
    'thead > tr > th:first-child': {
      borderTopLeftRadius: '6px' 
    },
    'thead > tr > th:last-child': {
      borderTopRightRadius: '6px' 
    },
    [['td', 'th']]: {
      padding: '.3em',
      paddingLeft: '1em',
      fontSize: '1em'
    },
    'tbody > tr > td': {
      borderColor: props.theme.global.colors["light-3"],
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid'
    },
    'tbody > tr > td:not(:last-child)': {
      borderRightWidth: '1px',
      borderRightStyle: 'solid'
    },
    'tr:last-child > td': {
      borderBottom: '0'
    }
  },
  [[
    "div.gatsby-highlight[data-language='alert-']",
    "div.gatsby-highlight[data-language='alert-warn']",
    "div.gatsby-highlight[data-language='alert-danger']",
    "div.gatsby-highlight[data-language='alert-success']"
  ]]: {
    pre: {
      fontSize: '1.025em',
      fontWeight: '600',
      lineHeight: '1.6em',
      borderRadius: '8px',
      padding: '12px',
      borderWidth: '1px',
      borderStyle: "solid",
      color: props.theme.global.colors["dark-5"],
      background: props.theme.global.colors["light-0"],
      borderColor: props.theme.global.colors["light-3"],
      code: {
        fontFamily: '"Muli", sans-serif',
        fontSize: '1em',
        lineHeight: '1em',
      }
    }
  },
  "div.gatsby-highlight[data-language='alert-warn']": {
    pre: {
      borderWidth: '1px',
      borderStyle: "solid",
      borderColor: props.theme.global.colors["status-warning"],
      backgroundColor: props.theme.global.colors["status-warning"] + '10',
    }
  },
  "div.gatsby-highlight[data-language='alert-danger']": {
    pre: {
      borderWidth: '1px',
      borderStyle: "solid",
      borderColor: props.theme.global.colors["status-error"],
      backgroundColor: props.theme.global.colors["status-error"] + '10',
    }
  },
  "div.gatsby-highlight[data-language='alert-success']": {
    pre: {
      borderWidth: '1px',
      borderStyle: "solid",
      borderColor: props.theme.global.colors["status-ok"],
      backgroundColor: props.theme.global.colors["status-ok"] + '10',
    }
  },
  'div.gatsby-highlight': {
    marginBottom: '2em',
    pre: {
      background: props.theme.global.colors["light-0"],
      padding: '12px',
      paddingRight: '12px',
      whiteSpace: 'normal'
    }
  },
  "div.gatsby-highlight[data-language='ssh']": {
    pre: {
      background: props.theme.global.colors["dark-4"],
      color: 'white',
      fontWeight: '500',
      padding: '12px',
      paddingRight: '12px',
      whiteSpace: 'normal',
      maxWidth: '600px'
    }
  },
  '@media print': {
    [['h1', 'h2', 'h3', 'h4', 'h5', 'h6']]: {
      fontSize: '1em',
      lineHeight: '1.1em',
      fontWeight: '700',
      marginTop: '0.1em',
      marginBottom: '0.8em',
      'a > svg': {
        display: 'none'
      },
      'a::after': {
        content: "'' !important"
      }
    },
    h1: {
      marginBottom: '1.2em'
    },
    h2: {
      fontSize: '1.2em',
      lineHeight: '1.2em',
    },
    [['ul', 'ol']]: {
      marginLeft: '0.35em',
      marginTop: '0',
      marginBottom: '1em',
      'li:last-child': {
        marginBottom: '0.25em'
      }
    },
    [['p', 'li']]: {
      fontSize: '1em',
      lineHeight: '1.45em'
    },
    li: {
      marginBottom: '.325em'
    },
    p: {
      display: 'block',
      marginTop: '0',
      marginBottom: '1em'
    },
  }
}))

export default LegalPage