import React from 'react'
import styled from 'styled-components'
import { Box } from 'grommet'

const Main = ({ children, ...rest }) => {
  return (
    <Container 
      id="main-section"
      as="section"
      {...rest}
    >
      {children}
    </Container>
  )
}

const Container = styled(Box)`
  @media print {
    
  }
`

export default Main