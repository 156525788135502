import React from 'react'
import styled from 'styled-components'
import { Text } from 'grommet'

const LogoLabel = (props) => {
  const { label, ...rest } = props
  return (
    <StyledText 
      a11yTitle="Documentation" 
      alignSelf="start" 
      color="dark-3"
      margin={{ vertical: "6px" }}
      {...rest}
     >
      {label}
    </StyledText>
  )
}

LogoLabel.defaultProps = {
  label: "Docs"
}

const StyledText = styled(Text)`
  font-family: 'Cantarell', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: red;
  border-radius: 4px;
  padding: 0 6px;
  color: ${props => props.theme.global.colors.brand};
  background: ${props => props.theme.global.colors["accent-light-1"]};
`

export default LogoLabel