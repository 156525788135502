import React from 'react'
import styled from 'styled-components'
import { Box, Text } from 'grommet'

const MenuItemDescription = (props) => {
  const { margin, children, ...rest } = props
  return (
    <Box
      margin={margin}
    >
      <ItemDescription {...rest}>
        {children}
      </ItemDescription>
    </Box>
  )
}

const ItemDescription = styled(Text)`
  font-size: 15px;
  font-weigth: 500;
  line-height: 1.3;
  color: ${props => props.theme.global.colors["dark-3"]};
`

export default MenuItemDescription