import React, { useState } from 'react'
import { Box, Text } from 'grommet'
import { Label, Submit, TextArea, TextInput } from '../index'

const FormInput = ({ input, label, flexBasis, required, error, setValue, clearErrors, onChange, ...rest }) => {
  const [inputValue, setInputValue] = useState(null)
  const isSubmit = input === "Submit"

  const onInputChange = (value) => {
    setInputValue(value)
    setValue(rest.name, value)
    clearErrors(rest.name)
    onChange && onChange(value)
  }

  return (
    <Box
      basis={flexBasis || "full"}
      pad={isSubmit ? {horizontal: "small", top: "medium"} : "small"}
    >
      {
        label && !isSubmit && <Label label={label} required={required} />
      }
      {
        input === "TextInput" && (
          <TextInput 
            error={error} 
            value={inputValue} 
            onChange={onInputChange} 
            {...rest} 
          />
        )
      }
      {
        input === "TextArea" && (
          <TextArea 
            error={error} 
            value={inputValue} 
            onChange={onInputChange} 
            {...rest} 
          />
        )
      }
      {
        input === "Submit" && <Submit label={label} {...rest} />
      }
      {
        error && <Text margin={{ top: "xsmall" }} color="red" size="small">{error}</Text>
      }
    </Box>
  )
}

export default FormInput