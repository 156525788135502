import React from 'react'
import styled, { keyframes } from 'styled-components'

const Diamond = ({ backgroundColor }) => {
  return (
    <Container xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 364">
      <defs>
        <linearGradient id="a" x1="0%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#FFF"/>
          <stop offset="100%" stopColor={backgroundColor} />
        </linearGradient>
      </defs>
      <path 
        fill="url(#a)" 
        fillRule="evenodd" 
        d="M0 0h1440v364c-232.677-10.076-472.677-46.373-720-108.89C472.677 192.591 232.677 107.554 0 0z"
      />
    </Container>
  )
}

const animation = keyframes`
  50% {
    transform: scale(1.2, 1);
  }
  70% {
    transform: scale(1.21., 1);
  }
  80% {
    transform: scale(1.3., 1);
  }
  100% {
    transform: scale(1, 1);
  }
`

const Container = styled.svg`
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  animation-delay: 2s;
  animation: 30s cubic-bezier(0.4, 0, 0.2, 1) 2s infinite normal none running ${animation};
`

export default Diamond