import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ backgroundColor, ...rest }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(name: {eq: "bgTop"}) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1080) {
                base64
                tracedSVG
                srcWebp
                srcSetWebp
                originalImg
                originalName
              }
            }
          }
        }
      `}
      render={data => {
        const imageData = data.desktop.childImageSharp.fluid
        return (
          <Container
            Tag="section"
            fluid={imageData}
            backgroundColor={backgroundColor}
          />
        )
      }}
    />
  )
}

const Container = styled(BackgroundImage)`
  background: ${props => props.theme.global.colors[`${props.backgroundColor}`]};
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default BackgroundSection