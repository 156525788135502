import React from 'react'
import styled, { keyframes } from 'styled-components'

const Flow = ({ backgroundColor, style }) => {
  return (
    <Container>
      <BackgroundImage xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1280" height="448" style={style} viewBox="0 0 1280 448">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_1" dataName="Rectangle 1" width="1280" height="448"/>
          </clipPath>
        </defs>
        <g id="Flow" clip-path="url(#clip-path)">
          <g id="Group_2" dataName="Group 2" transform="translate(1171.887 213.578)" opacity="0.5">
            <g id="Group_1" dataName="Group 1" transform="translate(0 0)">
              <path id="Path_1" dataName="Path 1" d="M4.68-3A7.68,7.68,0,1,1-3,4.68,7.684,7.684,0,0,1,4.68-3Z" transform="translate(3 3)" fill="#f0f2f9"/>
            </g>
          </g>
          <g id="Group_4" dataName="Group 4" transform="translate(832.006 252.138)" opacity="0.5">
            <g id="Group_3" dataName="Group 3" transform="translate(0 0)">
              <path id="Path_2" dataName="Path 2" d="M4.68-3A7.68,7.68,0,1,1-3,4.68,7.684,7.684,0,0,1,4.68-3Z" transform="translate(3 3)" fill="#f0f2f9"/>
            </g>
          </g>
          <g id="Group_6" dataName="Group 6" transform="translate(928.12 157.238)" opacity="0.5">
            <g id="Group_5" dataName="Group 5" transform="translate(0 0)">
              <path id="Path_3" dataName="Path 3" d="M4.68-3A7.68,7.68,0,1,1-3,4.68,7.684,7.684,0,0,1,4.68-3Z" transform="translate(3 3)" fill="#f0f2f9"/>
            </g>
          </g>
          <g id="Group_8" dataName="Group 8" transform="translate(211.2 288.831)" opacity="0.5">
            <g id="Group_7" dataName="Group 7" transform="translate(0 0)">
              <path id="Path_4" dataName="Path 4" d="M4.68-3A7.68,7.68,0,1,1-3,4.68,7.684,7.684,0,0,1,4.68-3Z" transform="translate(3 3)" fill="#f0f2f9"/>
            </g>
          </g>
          <g id="Group_10" dataName="Group 10" transform="translate(107.52 194.56)" opacity="0.5">
            <g id="Group_9" dataName="Group 9" transform="translate(0 0)">
              <path id="Path_5" dataName="Path 5" d="M4.68-3A7.68,7.68,0,1,1-3,4.68,7.684,7.684,0,0,1,4.68-3Z" transform="translate(3 3)" fill="#f0f2f9"/>
            </g>
          </g>
          <g id="Group_12" dataName="Group 12" transform="translate(593.92 245.76)" opacity="0.5">
            <g id="Group_11" dataName="Group 11" transform="translate(0 0)">
              <path id="Path_6" dataName="Path 6" d="M4.68-3A7.68,7.68,0,1,1-3,4.68,7.684,7.684,0,0,1,4.68-3Z" transform="translate(3 3)" fill="#f0f2f9"/>
            </g>
          </g>
          <g id="Group_14" dataName="Group 14" transform="translate(-304.168 209.528)">
            <g id="Group_13" dataName="Group 13" transform="translate(0 0)">
              <path id="Path_7" dataName="Path 7" d="M1500.052,42.359C1551.738,7.6,1603.5-27.153,1662.407-27.153M1500.062,42.359c-51.687,34.756-103.475,69.512-162.355,69.512m-162.24-69.512c51.686,34.756,103.45,69.512,162.355,69.512M1175.475,42.359C1123.788,7.6,1072-27.153,1013.12-27.153M850.88,42.359C902.566,7.6,954.329-27.153,1013.235-27.153M850.887,42.359C799.2,77.115,747.412,111.871,688.532,111.871M526.292,42.359c51.686,34.756,103.45,69.512,162.355,69.512M526.3,42.359C474.616,7.6,422.827-27.153,363.947-27.153M201.707,42.359C253.393,7.6,305.157-27.153,364.062-27.153M201.715,42.359C150.028,77.115,98.24,111.871,39.36,111.871M-122.88,42.359c51.686,34.756,103.45,69.512,162.355,69.512M-122.978,42.359C-174.664,7.6-226.453-27.153-285.333-27.153m.115,0" transform="translate(285.333 27.153)" fill="rgba(0,0,0,0)" stroke="#fff" stroke-linecap="square" stroke-width="2.173" stroke-dasharray="1 4"/>
            </g>
          </g>
          <g id="Group_16" dataName="Group 16" transform="translate(1641.432 220.16)">
            <g id="Group_15" dataName="Group 15" transform="translate(0 0)">
              <path id="Path_8" dataName="Path 8" d="M1500.052,35.88C1551.738,6.44,1603.5-23,1662.407-23M1500.062,35.88c-51.687,29.44-103.475,58.88-162.355,58.88m-162.24-58.88c51.686,29.44,103.45,58.88,162.355,58.88M1175.475,35.88C1123.788,6.44,1072-23,1013.12-23M850.88,35.88C902.566,6.44,954.329-23,1013.235-23M850.887,35.88C799.2,65.32,747.412,94.76,688.532,94.76M526.292,35.88c51.686,29.44,103.45,58.88,162.355,58.88M526.3,35.88C474.616,6.44,422.827-23,363.947-23M201.707,35.88C253.393,6.44,305.157-23,364.062-23M201.715,35.88C150.028,65.32,98.24,94.76,39.36,94.76M-122.88,35.88C-71.194,65.32-19.431,94.76,39.475,94.76M-122.978,35.88C-174.664,6.44-226.453-23-285.333-23m.115,0" transform="translate(285.333 23)" fill="rgba(0,0,0,0)" stroke="#fff" stroke-linecap="square" stroke-width="2" stroke-dasharray="1 4"/>
            </g>
          </g>
          <g id="Group_18" dataName="Group 18" transform="translate(1661.24 271.36)" opacity="0.39">
            <g id="Group_17" dataName="Group 17" transform="translate(0 0)">
              <path id="Path_9" dataName="Path 9" d="M1500.052,35.88C1551.738,6.44,1603.5-23,1662.407-23M1500.062,35.88c-51.687,29.44-103.475,58.88-162.355,58.88m-162.24-58.88c51.686,29.44,103.45,58.88,162.355,58.88M1175.475,35.88C1123.788,6.44,1072-23,1013.12-23M850.88,35.88C902.566,6.44,954.329-23,1013.235-23M850.887,35.88C799.2,65.32,747.412,94.76,688.532,94.76M526.292,35.88c51.686,29.44,103.45,58.88,162.355,58.88M526.3,35.88C474.616,6.44,422.827-23,363.947-23M201.707,35.88C253.393,6.44,305.157-23,364.062-23M201.715,35.88C150.028,65.32,98.24,94.76,39.36,94.76M-122.88,35.88C-71.194,65.32-19.431,94.76,39.475,94.76M-122.978,35.88C-174.664,6.44-226.453-23-285.333-23m.115,0" transform="translate(285.333 23)" fill="rgba(0,0,0,0)" stroke="#fff" stroke-width="1"/>
            </g>
          </g>
          <g id="Group_20" dataName="Group 20" transform="translate(-773.747 271.36)" opacity="0.39">
            <g id="Group_19" dataName="Group 19" transform="translate(0 0)">
              <path id="Path_10" dataName="Path 10" d="M1796.713,35.88C1848.4,6.44,1900.162-23,1959.068-23M1796.723,35.88c-51.686,29.44-103.475,58.88-162.355,58.88m-162.24-58.88c51.687,29.44,103.45,58.88,162.355,58.88M1472.135,35.88C1420.449,6.44,1368.66-23,1309.78-23M1147.54,35.88C1199.226,6.44,1250.99-23,1309.9-23M1147.548,35.88c-51.686,29.44-103.475,58.88-162.355,58.88M822.953,35.88C874.639,65.32,926.4,94.76,985.308,94.76M822.963,35.88C771.276,6.44,719.488-23,660.608-23M498.368,35.88C550.054,6.44,601.817-23,660.723-23M498.375,35.88C446.689,65.32,394.9,94.76,336.02,94.76M173.78,35.88c51.686,29.44,103.45,58.88,162.355,58.88M173.683,35.88C122,6.44,70.208-23,11.328-23m-162.24,58.88C-99.226,6.44-47.463-23,11.443-23M-150.9,35.88C-202.591,65.32-254.38,94.76-313.26,94.76M-475.5,35.88c51.686,29.44,103.45,58.88,162.355,58.88" transform="translate(475.5 23)" fill="rgba(0,0,0,0)" stroke="#fff" stroke-width="1"/>
            </g>
          </g>
        </g>
      </BackgroundImage>
    </Container>
  )
}

const animation = keyframes`
  0% {
		transform: rotate(0deg) scale(1);
	}
	10% {
		transform: rotate(8deg) scale(1.05);
	}
	20% {
		transform: rotate(16deg) scale(1.1);
	}
	30% {
		transform: rotate(24deg) scale(1.1);
	}
	40% {
		transform: rotate(16deg) scale(1.15);
	}
	50% {
		transform: rotate(24deg) scale(1.2);
	}
	60% {
		transform: rotate(32deg) scale(1.15);
	}
	70% {
		transform: rotate(24deg) scale(1);
	}
	80% {
		transform: rotate(16deg) scale(1.1);
	}
	90% {
		transform: rotate(8deg) scale(1.1);
	}
	100% {
		transform: rotate(0deg) scale(1);
	}
`

const Container = styled.div`
  position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: -2;
	overflow: visible;
`

const BackgroundImage = styled.svg`
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  transform-origin: center;
  will-change: transform;
  animation-delay: 2s;
  fill: white;
`

export default Flow