import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"
import { FormAdd, FormSubtract } from 'grommet-icons'
import { Accordion, AccordionPanel, Anchor, Box } from 'grommet'
import { useOptions } from '../../../state/hooks'
import { AnchorLink, Logo } from '../../../components'

const DocsNav = ({ categoryData, pageData, menuLinks }) => {
  const { options, toggleAllPanels, setActivePanels } = useOptions()
  const entries = pageData
  const categories = categoryData.map(item => item.category.frontmatter.title)  

  const Expander = () => {
    const linkText = options.isExpanded ? "Collapse All" : "Expand All"
    const linkIcon = options.isExpanded ? <FormSubtract size="small" /> : <FormAdd size="small" />

    return (
      <ExpandLink 
        label={linkText} 
        icon={linkIcon} 
        color="dark-5" 
        onClick={() => toggleAllPanels(categories)}
      />
    )
  }

  return (
    <Container 
      gridArea="sidebar" 
      width="315px" 
      pad={{ right: "medium"}}
      margin={{ left: "xlarge" }} 
      border={{ size: "xsmall", side: "right", color: "light-3" }}
    >
      <div style={{paddingTop: "20px", paddingBottom: "40px"}}>
        <AnchorLink path="/">
          <Box margin={{ bottom: "large" }} align="center" direction="row">
            <Logo showLogo={false} logoLabel="Docs" theme="dark" />
          </Box>
        </AnchorLink>
        <Box margin={{ bottom: "medium" }}>
          {
            menuLinks.map((item, index) => (
              <NavItemLink 
                key={`menuLink-${index}`} 
                to={item.link.frontmatter.path}
                primary={true}
              >
                {item.link.frontmatter.title}
              </NavItemLink>
            ))
          }
        </Box>
        <Expander />
        <NavContainer 
          multiple={true} 
          animate={false} 
          activeIndex={options.activePanels}
          onActive={(event) => setActivePanels(event)}
        >
          {
            categories && categories.filter(item => item != "Overview").map((category, catIndex) => {
              const items = entries.filter(item => item.post.frontmatter.category === category)
              return (
                <NavItem key={`${category}-${catIndex}`} label={category}>
                  {
                    items.map((item, index) => (
                      <NavItemLink key={`${item.post.fields.slug}-${index}`} to={item.post.fields.slug}>
                        {item.post.frontmatter.title}
                      </NavItemLink>
                    ))
                  }
                </NavItem>
              )
            })
          }
        </NavContainer>
      </div>
    </Container>
  )
}

DocsNav.defaultProps = {
  categoryData: [],
  pageData: {},
  menuLinks: []
}

const Container = styled(Box)`
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
`

const NavContainer = styled(Accordion)`
  h4 {
    margin: .6em 0;
    font-size: 14px;
    font-weight: 800;
    line-height: 1.1;
    letter-spacing: 0.112em;
    text-transform: uppercase;
    transition: color .3s;
  }

  div {
    border: 0;
    margin: 0;
    padding: 0;
  }

  svg {
    fill: ${props => props.theme.global.colors["light-3"]};
    stroke: ${props => props.theme.global.colors["light-3"]};
  }
`

const NavItem = styled(AccordionPanel)`
  h4 {
    font-size: 0.825em;
  }

  > div { 
    margin-top: 1em;
  }

  &button {
    background: blue !important;
  }
`

const NavItemLink = styled(Link)`
  color: ${props => props.primary ? props.theme.global.colors.black : props.theme.global.colors["dark-1"]}; ${props => props.theme.global.colors["dark-2"]};
  font-weight: ${props => props.primary ? 700 : 400};
  font-size: 0.950em;
  line-height: 2.6;
  letter-spacing: .6px;
  transition: color .3s;
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.global.colors["dark-6"]};
  }

  &[aria-current='page'] {
    font-weight: ${props => props.primary ? 700 : 600};
    color: ${props => props.theme.global.colors.brand};
  }
`

const ExpandLink = styled(Anchor)`
  text-transform: uppercase;
  letter-spacing: .8px;
  font-size: .7em;

  span > div {
    width: 6px;
  }
`

export default DocsNav