import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Anchor, Box, Text } from 'grommet'
import { Github } from 'grommet-icons'
import { useMount, useWindowScroll, useWindowSize } from 'react-use/lib'
import { slugger } from '../../../utils'

const DocsAside = ({ data, location, contentRef }) => {
  const {y} = useWindowScroll()
  const {width, height} = useWindowSize()
  const [offsets, setOffsets] = useState([])
  const [imagesToLoad, setImagesToLoad] = useState(0)
  const [imagesLoaded, setImagesLoaded] = useState(0)
  const imagesReady = imagesToLoad === imagesLoaded

  useEffect(() => {
    const headings = contentRef.current && contentRef.current.querySelectorAll('h1, h2')
    headings && setOffsets(
      Array.from(headings)
        .map(heading => {
          const anchor = heading.querySelector('a')
          if (!anchor) { return null }
          return {
            id: heading.id,
            offset: heading.offsetTop + anchor.offsetTop
          };
        })
        .filter(Boolean)
    )
  }, [width, height, contentRef, imagesReady])

  useMount(() => {
    if (location.hash) {
      // turn numbers at the beginning of the hash to unicode
      // see https://stackoverflow.com/a/20306237/8190832
      const hash = location.hash.toLowerCase().replace(/^#(\d)/, '#\\3$1 ')
      try {
        const hashElement = contentRef.current.querySelector(hash)
        if (hashElement) {
          hashElement.scrollIntoView();
        }
      } catch (error) {
        // let errors pass
      }
    }

    let toLoad = 0
    const images = contentRef.current && contentRef.current.querySelectorAll('img')
    images && images.forEach(image => {
      if (!image.complete) {
        image.addEventListener('load', handleImageLoad)
        toLoad++
      }
    })

    setImagesToLoad(toLoad)
  })

  const handleImageLoad = () => {
    setImagesLoaded(prevImagesLoaded => prevImagesLoaded + 1);
  }  

  let activeAsideItem = null
  const windowOffset = height / 2
  const scrollTop = y + windowOffset

  for (let i = offsets.length - 1; i >= 0; i--) {
    const {id, offset} = offsets[i]
    if (scrollTop >= offset) {
      activeAsideItem = id
      break
    }
  }

  const AsideNav = () => {
    return (
      <Box margin={{ bottom: "large" }}>
        {
          data.post.headings.map((item, index) => {
            const slug = slugger([item.value])
            const isActive = activeAsideItem === slug 
            return (
              <AsideNavItemLink 
                key={index}
                to={`${location.pathname}#${slug}`}
                isActive={isActive}
                title={item.value}
              >
                <AsideNavItem isActive={isActive} margin={{ bottom: "xsmall" }}>{item.value}</AsideNavItem>
              </AsideNavItemLink>
            )
          })
        }
      </Box>
    )
  }

  return (
    <Box basis="1/4">
      <AsideContent>
        <AsideHeading weight={700} margin={{ bottom: "small" }}>
          {data.post.frontmatter.title}
        </AsideHeading>
        <AsideNav />
        {
          process.env.NODE_ENV !== "production" && (
            <ActionAnchor 
              href={`https://github.com/hippobyte/website/tree/master/content/site/${location.pathname.slice(0, -1)}.md`} 
              label="Edit on GitHub" 
              a11yTitle="Edit this documentation entry on GitHub"
              color="dark-2"
              icon={<Github color="plain" />}
            />
          )
        }
      </AsideContent>
    </Box>
  )
}

const ActionAnchor = styled(Anchor)`
  font-size: .925em;
  color: ${props => props.isActive ? props.theme.global.colors["brand"] : props.theme.global.colors["dark-4"]};
  transition: color .1s;

  &:hover {
    color: ${props => props.isActive ? props.theme.global.colors["brand"] : props.theme.global.colors["dark-3"]};
  }
`

const AsideHeading = styled(Text)`
  font-size: 1em;
`

const AsideNavItem = styled(Box)`
  transition: color .1s;

  &:hover > div {
    color: ${props => props.theme.global.colors["dark-3"] };
  }
`

const AsideNavItemLink = styled(AnchorLink)`
  font-size: .925em;
  font-weight: 600;
  color: ${props => props.isActive ? props.theme.global.colors["brand"] : props.theme.global.colors["dark-4"]};
  transition: color .1s;
  text-decoration: none;

  &:hover {
    color: ${props => props.isActive ? props.theme.global.colors["brand"] : props.theme.global.colors["dark-3"]};
  }
`

const AsideContent = styled(Box)`
  position: sticky;
  top: 120px;
`

export default DocsAside