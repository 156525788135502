import React from 'react'
import styled from 'styled-components'
import { Heading } from 'grommet'

const PageSubTitle = ({ align, pageTheme, label, breakpoint, ...rest }) => {
  const color = pageTheme === "light" ? "light-0" : "dark-1"
  const textAlign = align === "center" ? align : "left"
  const fontSize = breakpoint === "large" ? "xsmall" : breakpoint === "medium" ? "1.2em" : "1em"
  const size = pageTheme === "light" ? "xsmall" : fontSize
  if (label) {
    return (
      <PageSubTitleHeading 
        level={2} 
        size={size} 
        color={color}
        textAlign={textAlign}
        {...rest}
      >
        {label}
      </PageSubTitleHeading>
    )
  } else {
    return null
  }
}

const PageSubTitleHeading = styled(Heading)`
  font-weight: 500;
  line-height: 1.8em;
  letterSpacing: -0.6px;

  @media print {
    margin-top: 0;
    margin-bottom: 0.2em;
    margin-left: 0;
    margin-right: 0;

    > span {
      font-size: 0.55em;
      line-height: 1em;
    }
  }
` 

export default PageSubTitle