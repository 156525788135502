import React from 'react'
import { Box, Text } from 'grommet'
import { AnchorButton } from '../../../components'

const TalkWithExpert = ({ title, subTitle, pad, ...rest}) => {
  return (
    <Box 
      background="#2684ff" 
      align="center"
      pad={{ horizontal: "11vw", vertical: "xlarge", ...pad }}
      {...rest}
    >
      <Box>
        <Box align="center" pad={{ bottom: "medium" }}>
          <Text weight={700} size="xxlarge" color="white">{title || "Ready to be your organization's hero?"}</Text>
          <Text weight={600} size="large" margin={{ vertical: "medium" }} color="light-3">{subTitle || "Schedule a call today to speak with an expert.."}</Text>
        </Box>
        <AnchorButton 
          path="/contact/schedule-demo"
          style={{
            border: "1px solid white",
            borderRadius: "4px",
            height: "34px"
          }}
        >
          <Box
            align="center"
          >
            <Text weight={700} color="white">Schedule a Call</Text>
          </Box>
        </AnchorButton>
      </Box>
    </Box>
  )
}

export default TalkWithExpert