import React from 'react'
import { Box, Heading, Text } from 'grommet'

const List = ({ header, data, ...rest }) => {
  return (
    <Box {...rest}>
      { header && <Header {...header} /> }
      {
        data.map((item, index) => {
          return (
            <ListItem key={index} {...item} />
          )
        })
      }
    </Box>
  )
}

const Header = ({ label, ...rest }) => (
  <Heading level={2} color="dark-4" alignSelf="center" margin={{ top: "0" }} style={{ fontWeight: 900 }} {...rest}>
    {label}
  </Heading>
)

const ListItem = ({ title, titleOpts, description, descriptionOpts }) => {
  const Title = ({ label, ...rest }) => (
    <Heading level={3} color="dark-4" margin={{ bottom: "xsmall"}} size="xsmall" style={{ fontWeight: 900 }} {...rest}>
      {label}
    </Heading>
  )
  
  const Description = ({ label, ...rest }) => (
    <Text color="dark-4" {...rest}>
      {label}
    </Text>
  )

  return (
    <Box margin={{ bottom: "medium" }}>
      { title && <Title label={title} {...titleOpts} /> }
      { description && <Description label={description} {...descriptionOpts} /> }
    </Box>
  )
}

List.defaultProps = {
  data: []
}

export default List