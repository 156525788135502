import React, { useEffect, useState } from 'react'
import  numeral from 'numeral'
import { Box, Heading, Paragraph, Text, TextInput } from 'grommet'
import { RangeSlider } from '../../../components/form'

const PriceCalculator = () => {
  const [value, setValue] = useState(0)
  const [transformedValue, setTransformedValue] = useState(0)
  const [priceMetrics, setPriceMetrics] = useState({})
  const [tier, setTier] = useState("Enterprise")

  const creditCost = { Starter: 0.50, Growth: 0.75, Enterprise: 1.00 }

  useEffect(() => {
    onChange({ value: value, transformedValue: transformedValue })
  }, [tier])

  const onChange = event => {
    const eValue = numeral(event.value).value()
    const eTransformed = numeral(event.transformedValue).value()
    setValue(eValue)
    setTransformedValue(eTransformed)
    calculateMonthlyCost(eTransformed)
  }

  const tierValues = (count) => {
    // 1 Million
    if (count >= 1000000 && count < 10000000) {
      return { tier: 1, tierValue: 1000000, value: count / 1000000, credits: 1500, incremental: 222.22 }
    }
    // 10 Million
    if (count >= 10000000 && count < 100000000) {
      return { tier: 10, tierValue: 10000000, value: count / 1000000, credits: 2500, incremental: 44.44 }
    }
    // 100 Million
    if (count >= 100000000 && count < 1000000000) {
      return { tier: 100, tierValue: 100000000, value: count / 1000000, credits: 6500, incremental: 8.88 }
    }
    // 1 Billion
    if (count >= 1000000000 && count < 10000000000) {
      return { tier: 1, tierValue: 1000000000, value: count / 1000000, credits: 14500, incremental: 4.00 }
    }
    // 10 Billion
    if (count >= 10000000000) {
      return { tier: 10, tierValue: 10000000000, value: count / 1000000, credits: 46500, incremental: 1.00 }
    }
    // default
    return { tier: 0, tierValue: 0, value: (count === 0 ? 0 : count / 1000000), credits: 0, incremental: 1500.00 }
  }

  const calculateMonthlyCost = (count) => {
    const tiers = tierValues(count)
    const creditsEventRatio = tiers.credits === 0 ? tiers.incremental : tiers.credits / tiers.tierValue * 1000000
    const tierUsage = (tiers.value - tiers.tier)
    const tierDiscount =  tierUsage * tiers.incremental
    const creditUsage = tierDiscount + tiers.credits
    const totalCredits = creditUsage > 0 && creditUsage < 1 ? 1 : creditUsage
    const cost = totalCredits * creditCost[tier]
    setPriceMetrics({
      monthly: cost,
      annual: cost*12,
      totalCredits: totalCredits,
      tier: tiers.tier,
      tierCredits: tiers.credits,
      tierValue: tiers.tierValue,
      incrementalCredits: tiers.incremental,
      creditsEventRatio: creditsEventRatio,
      currency: "USD"
    })
  }

  const Calculator = ({ themeColor, description, estimate }) => {

    const MonthlyCreditLabel = () => {
      if (estimate.tierValue > 0) {
        return <><strong>{numeral(estimate.tierValue).format('0,0a')}</strong> Tier Credits</>
      }
      return 'Tier Credits'
    }
  
    return (
      <Box 
        round="xsmall" 
        background="white" 
      >
        <Box 
          round="xsmall" 
          pad="medium"
          justify="between"
          style={{ boxShadow: `0 0 0 1px ${themeColor}` }}
        >
          <Box>
            <MonthlyDataEvent
              mde={transformedValue}
              tier={tier}
            />
            <MonthlyCredit
              label={<MonthlyCreditLabel />}
              value={numeral(estimate.tierCredits).format('0,0')}
            />
            <MonthlyCredit
              label="Incremental Credits"
              value={numeral(estimate.totalCredits - estimate.tierCredits).format('0,0')}
            />
            <MonthlyCredit
              label="Billable Credits"
              value={numeral(estimate.totalCredits).format('0,0')}
              subtotal={true}
            />
            <MonthlyCredit
              label="Price per Credit"
              value={`${numeral(creditCost[tier]).format('0,0.00')} USD`}
            />
            <MonthlyEstimate
              estimate={estimate}
              mde={value}
            />
            <Text
              color="dark-1"
              size="small"
              margin={{
                left: "xsmall",
                vertical: "medium"
              }}
              weight={500}
            >
              <strong>{numeral(estimate.incrementalCredits).format('0,0.00')}</strong> credits per <strong>1 MILLION MDEs</strong> additional rows.
            </Text>
          </Box>
        </Box>
      </Box>
    )
  }
  
  return (
    <Box 
      background="light-0" 
      round="xsmall" 
      margin={{ top: "xlarge", horizontal: "6.5vw" }}
    >
      <Box
        direction="row"
        justify="between"
        pad="large"
        wrap={true}
      >
        <Box flex="grow" basis="medium" margin={{ right: "large", bottom: "large" }}>
          <Text 
            color="dark-3" 
            margin="0"
            size="xxlarge"
            wordBreak="break-word"
            style={{ 
              fontWeight: 600, 
              fontFamily: "'Barlow Semi Condensed', sans-serif",
            }}
          >
            Estimate Monthly Usage Cost
          </Text>
          <Text 
            color="dark-1" 
            margin={{ top: "medium" }}
            size="large"
            wordBreak="break-word"
          >
            Monthly service cost is calculated based on credits. A credit is exchanged for Monthly Data Events (MDEs). As your Monthly Data Events increase, each credit's `buying` power increases. Increased usage equates to lower cost.
          </Text>
          <TierSelector
            tier={tier}
            setTier={setTier}
          />
          <MDESelector
            value={value}
            transformedValue={transformedValue}
            onChange={onChange}
          />
        </Box>
        <Box flex="shrink" basis="440px">
          <Calculator
            estimate={priceMetrics}
          />
        </Box>
      </Box>
    </Box>
  )
}

const TierSelector = ({ tier, setTier }) => {

  const TierButton = ({ label, ...rest }) => {

    const onClick = () => {
      setTier(label)
    }

    return (
      <Box
        width="small"
        height="xxsmall"
        align="center"
        justify="center"
        background={label === tier ? "brand" : "#bdc1d4"}
        onClick={onClick}
        {...rest}
      >
        <Text 
          weight={600} 
          color={label === tier ? "white" : "dark-1"}
        >
          {label}
        </Text>
      </Box>
    )
  }

  return (
    <Box pad={{ bottom: "large" }} margin={{ bottom: "small" }} border={{ side: "bottom", size: "xsmall", color: "light-5" }}>
      <Heading
        level={3}
        color="dark-2"
        margin={{
          bottom: "0"
        }}
      >
        Select Product Tier
      </Heading>
      <Text
        wordBreak="break-word"
        color="dark-2"
        margin={{
          top: "small",
          bottom: "medium"
        }}
      >
        Select tier based on product features required by your team to deliver the most business value. You may upgrade or downgrade tiers as your business needs change.
      </Text>
      <Box 
        direction="row"
      >
        <TierButton 
          label="Starter" 
          style={{ borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px" }} 
        />
        <TierButton 
          label="Growth" 
          border={{ side: "vertical", color: "white" }} 
        />
        <TierButton 
          label="Enterprise" 
          style={{ borderTopRightRadius: "4px", borderBottomRightRadius: "4px"  }}
        />
      </Box>
    </Box>
  )
}

const MDESelector = ({ transformedValue, value, onChange }) => {
  return (
    <Box flex={false}>
      <Heading
        level={3}
        color="dark-2"
        margin={{
          bottom: "0"
        }}
      >
        Enter Monthly Data Events (MDEs)
      </Heading>
      <Text
        wordBreak="break-word"
        color="dark-2"
        margin={{
          top: "small",
          bottom: "medium"
        }}
      >
         Monthly Data Events (MDEs) is the total monthly synchronized records. Initial synchronizations do not count towards Monthly Data Event usage.
      </Text>
      <Box
        direction="row"
        align="center"
        wrap={false}
      >
        <Box basis="160px" background="white" margin={{ right: "small" }}>
          <TextInput 
            style={{ fontWeight: 900, textAlign: "right", color: "blue", borderColor: "blue" }}
            min={0}
            max={100000000000}
            maxLength={15}
            pattern="\d*" 
            value={numeral(transformedValue).format('0,0')}
            placeholder="Enter MDEs"
            onChange={(e) => onChange({ value: e.target.value, transformedValue: e.target.value })}
          />
        </Box>
        <Box flex="grow">
          <RangeSlider
            min={0}
            max={100000000000}
            steps={[10000, 100000, 1000000, 10000000, 100000000, 1000000000, 10000000000]}
            value={value}
            onChange={onChange}
          />
        </Box>
      </Box>
    </Box>
  )
}

const MonthlyDataEvent = ({ mde, tier }) => (
  <Box
    direction="row"
    justify="between"
    align="baseline"
    pad={{
      bottom: "small",
      horizontal: "small"
    }}
    margin={{
      vertical: "small"
    }}
    border={{ 
      side: "bottom",
      color: "black",
      size: "small"
    }}
  >
    <Box>
      <Heading 
        level={2}
        color="dark-3" 
        margin="0"
        style={{ 
          fontWeight: 600, 
          fontFamily: "'Barlow Semi Condensed', sans-serif",
        }}
      >
        MDEs
      </Heading>
      <Text size="small" color="brand" weight={900} style={{ textTransform: "uppercase" }}>
        {tier}
      </Text>
    </Box>
    <Box
      align="end"
      >
      <Text
        color="dark-3"
        size="xlarge"
        weight={500}
      >
        {numeral(mde).format('0,0')}
      </Text>
      <Text size="small" color="dark-3" weight={500}>
        Monthly Data Events
      </Text>
    </Box>
  </Box>
)

const MonthlyCredit = ({ label, value, subtotal }) => (
  <Box
    direction="row"
    justify="between"
    align="baseline"
    pad={{
      vertical: "medium",
      horizontal: "small"
    }}
    border={{ 
      side: "bottom",
      color: "light-3",
      small: "xsmall"
    }}
    background={subtotal && "light-0"}
  >
    <Text 
      color="dark-3" 
      weight={subtotal ? 600 : 400}
      size="large"
      style={{ 
        fontFamily: "'Barlow Semi Condensed', sans-serif",
      }}
    >
      {label}
    </Text>
    <Box align="end">
      <Text
        color="dark-3"
        size="large"
        weight={subtotal ? 600 : 400}
      >
        {value}
      </Text>
    </Box>
  </Box>
)

const MonthlyEstimate = ({ estimate }) => (
  <Box
    direction="row"
    justify="between"
    align="baseline"
    pad={{
      vertical: "small",
      horizontal: "small"
    }}
    margin={{
      top: "large"
    }}
    round="xsmall"
    background="brand"
  >
    <Text
      color="white"
      size="xlarge"
      weight={700}
    >
      Estimate
    </Text>
    <Box
      align="end"
      >
      <Text
        color="white"
        size="xlarge"
        weight={700}
      >
        $ {numeral(estimate.monthly).format('0,0')}
      </Text>
      <Text size="small" color="white" weight={700}>
        per month {estimate.currency}
      </Text>
    </Box>
  </Box>
)

export default PriceCalculator