import React from 'react'
import styled from 'styled-components'
import { Box, Text } from 'grommet'
import { FormNext } from 'grommet-icons'
import { AnchorLink } from '../../../components'

const Breadcrumb = ({ data, ...rest }) => {
  return (
    <Box direction="row" align="center" gap="xsmall" margin={{ top: "medium", horizontal: "11vw" }} {...rest}>
      {
        data && data.map((item, index) => {
          const separator = data.length-1 !== index
          return (
            <BreadcrumbItem 
              {...item} 
              separator={separator} 
            />
          )
        })
      }
    </Box>
  )
}

const BreadcrumbItem = ({ label, path, separator }) => (
  <BreadcrumbItemContainer direction="row" gap="xsmall">
    {
      separator ? (
        <Text size="0.95em"><AnchorLink style={{ fontWeight: 700 }} label={label} path={path} /></Text>
      ) : (
        <Text size="0.95em" color="dark-6">{label}</Text>
      )
    }
    {
      separator && <FormNext size="1.25em" color="dark-6" />
    }
  </BreadcrumbItemContainer>
)

BreadcrumbItem.defaultProps = {
  path: "/"
}

const BreadcrumbItemContainer = styled(Box)`
  a::after {
    display: none !important;
  }
`

export default Breadcrumb