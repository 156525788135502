import React from 'react'
import styled from 'styled-components'
import { Box, Text } from 'grommet'

const MenuItemHeader = ({ label, description, icon, children, ...rest }) => {  
  return (
    <Box direction="row" align="center" flex="grow">
      {
        icon && (
          <IconContainer>
            {icon}
          </IconContainer>
        )
      }
      <Box>
        <Text 
          weight={700} 
          color="dark-4"
          size="small"
          style={{ textTransform: "uppercase" }}
          {...rest}
        >
          {label || children}
        </Text>
        <Text 
          weight={600} 
          color="dark-6"
          size="small"
        >
          {description}
        </Text>
      </Box>
    </Box>
  )
}

const IconContainer = styled.div`
  position: relative;
  display: inline-block;
  top: 4px;
  margin-right: 14px;
`

export default MenuItemHeader