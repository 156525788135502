import React from 'react'
import styled, { keyframes, css } from 'styled-components'

const Gradient = ({ backgroundColor, theme, themeColors, animate, style }) => {
  const colors = gradientColors(theme, themeColors)
  return (
    <Container>
      <BackgroundImage xmlns="http://www.w3.org/2000/svg" width="1280" height="1280" viewBox="0 0 1280 1280" style={style} xmlnsXlink="http://www.w3.org/1999/xlink" animate={animate}>
        <defs>
          <radialGradient id="radial-gradient" cx="0.5" cy="0.5" r="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor={colors.gradient0.start} stopOpacity="0.341"/>
            <stop offset="1" stopColor={colors.gradient0.stop} stopOpacity="0.149"/>
          </radialGradient>
          <radialGradient id="radial-gradient-2" cx="0.881" cy="0.328" r="0.561" gradientTransform="matrix(0.974, -0.228, 0.152, 0.651, -0.027, 0.315)" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor={colors.gradient1.start}/>
            <stop offset="1" stopColor={colors.gradient1.stop} stopOpacity="0"/>
          </radialGradient>
          <radialGradient id="radial-gradient-3" cx="0.822" cy="0.266" r="0.561" xlinkHref="#radial-gradient-2"/>
          <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor={colors.gradient2.start} stopOpacity="0.412"/>
            <stop offset="1" stopColor={colors.gradient2.stop} stopOpacity="0.263"/>
          </linearGradient>
          <linearGradient id="linear-gradient-2" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor={colors.gradient3.start} stopOpacity="0.616"/>
            <stop offset="1" stopColor={colors.gradient3.stop} stopOpacity="0"/>
          </linearGradient>
          <clipPath id="clip-Background">
            <rect width="1280" height="1280"/>
          </clipPath>
        </defs>
        <g id="Background" clipPath="url(#clip-Background)">
          <g id="Background-2" dataName="Background">
            <ellipse id="Ellipse_3" dataName="Ellipse 3" cx="592.5" cy="572.5" rx="592.5" ry="572.5" transform="translate(40 94)" opacity="0.721" fill="url(#radial-gradient)"/>
            <ellipse id="Ellipse_1" dataName="Ellipse 1" cx="387" cy="598" rx="387" ry="598" transform="translate(-167.977 595.699) rotate(-45)" opacity="0.341" fill="url(#radial-gradient-2)"/>
            <path id="Union_4" dataName="Union 4" d="M228.58,722.193c112.759,152.263,122.87,199.643,98.434,329.5-84.861,265.745-439.887,467.169-248.579,49.156C349.794,507.921-469.835,1320.46-768.507,909.373S-155.055,412.019,228.58,722.193Z" transform="matrix(0.914, -0.407, 0.407, 0.914, 494.07, -120.25)" opacity="0.579" fill="url(#linear-gradient)"/>
            <path id="Union_1" dataName="Union 1" d="M1203.113,631.134c104.5,319.515-186.387,639.226-522.477,639.226S1413.661,869.708,356.145,268.8,1098.614,311.619,1203.113,631.134Z" transform="translate(0.491 2.059)" opacity="0.579" fill="url(#linear-gradient-2)"/>
            <path id="Union_2" dataName="Union 2" d="M1203.113,631.134c104.5,319.515-186.387,639.226-522.477,639.226S1413.661,869.708,356.145,268.8,1098.614,311.619,1203.113,631.134Z" transform="translate(15.491 -6.941)" opacity="0.579" fill="url(#linear-gradient-2)"/>
            <path id="Union_3" dataName="Union 3" transform="translate(84.764 355.059)" opacity="0.579" fill="url(#linear-gradient-2)"/>
            <ellipse id="Ellipse_2" dataName="Ellipse 2" cx="387" cy="598" rx="387" ry="598" transform="translate(549.067 155.545) rotate(42)" opacity="0.341" fill="url(#radial-gradient-3)"/>
          </g>
        </g>
      </BackgroundImage>
    </Container>
  )
}

Gradient.defaultProps = {
  themeColors: ["#e84d9b", "#fff", "#4d5fe8", "#fff", "#1484bf", "#f438e5", "#1428bf", "#8900ff"],
  animate: false
}

const gradientColors = (theme, themeColors) => {
  switch(theme) {
    case "blue":
      return {
        gradient0: {
          start: "blue",
          stop: "#fff"
        },
        gradient1: {
          start: "red",
          stop: "#fff"
        },
        gradient2: {
          start: "green",
          stop: "#f438e5"
        },
        gradient3: {
          start: "orange",
          stop: "#8900ff"
        }
      }
    default:
      return {
        gradient0: {
          start: themeColors[0],
          stop: themeColors[1]
        },
        gradient1: {
          start: themeColors[2],
          stop: themeColors[3],
        },
        gradient2: {
          start: themeColors[4],
          stop: themeColors[5],
        },
        gradient3: {
          start: themeColors[6],
          stop: themeColors[7],
        }
      }
  }
}

const animation = keyframes`
  0% {
		transform: rotate(0deg) scale(1);
	}
	10% {
		transform: rotate(8deg) scale(1.05);
	}
	20% {
		transform: rotate(16deg) scale(1.1);
	}
	30% {
		transform: rotate(24deg) scale(1.1);
	}
	40% {
		transform: rotate(16deg) scale(1.15);
	}
	50% {
		transform: rotate(24deg) scale(1.2);
	}
	60% {
		transform: rotate(32deg) scale(1.15);
	}
	70% {
		transform: rotate(24deg) scale(1);
	}
	80% {
		transform: rotate(16deg) scale(1.1);
	}
	90% {
		transform: rotate(8deg) scale(1.1);
	}
	100% {
		transform: rotate(0deg) scale(1);
	}
`

const Container = styled.div`
  position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: -2;
	overflow: visible;
`

const BackgroundImage = styled.svg`
  position: absolute;
  opacity: 0.2;
  top: 10vh;
  left: -40vw;
  width: calc(90vw + 25vh);
  height: auto;
  transform-origin: center;
  will-change: transform;

  ${props => props.animate && css`
    animation-delay: 2s;
    animation: ${animation} 60s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  `}
`

export default Gradient