import React from 'react'
import { Anchor } from 'grommet'
import { RoutedAnchor } from '../index'

const AnchorLink = (props) => {
  const { id, path, mouseEnter, mouseLeave, label, children, ...rest } = props

  const handleMouseEnter = () => {
    mouseEnter && mouseEnter(path || id)
  }

  const handleMouseLeave = () => {
    mouseLeave && mouseLeave(path || id)
  }

  return (
    <Anchor
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      path={path}
      label={label || children}
      {...rest}
      as={RoutedAnchor}
    />
  )
}

export default AnchorLink