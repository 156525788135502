import React from 'react'
import styled, { css } from 'styled-components'
import { TextInput as Input } from 'grommet'

const TextInput = ({ error, onChange, ...rest }) => {
  return (
    <Container
      isError={error}
      onChange={event => onChange && onChange(event.target.value)}
      {...rest}
    />
  )
}

TextInput.defaultProps = {
  size: "small"
}

const Container = styled(Input)`
  background: white;

  ${props => props.size === "small" && css`
    padding: 8px 12px;
  `}

  ${props => props.isError && css`
    border: 1px solid red;
  `}

  &:focus {
    color: ${props => props.theme.global.colors.black};
    border: 1px solid ${props => props.theme.global.colors.brand};
    ${props => props.isError && css`
      border: 1px solid red;
    `}
  }
`

export default TextInput