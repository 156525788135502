import React from 'react'
import Img from 'gatsby-image'
import { Box, Text, Paragraph } from 'grommet'
import { Next } from 'grommet-icons'
import { AnchorLink } from '../../../components'

const Article = ({ header, title, description, featuredImage, productImage, connector, pad, path, basis, margin }) => {
  const Header = () => {
    return (
      <Text 
        margin="0"
        weight={connector ? "900" : "700"}
        size={connector ? "small" : "xsmall"}
        color="brand"
        margin={{ bottom: "small" }}
        style={{ textTransform: "uppercase" }}
      >
        {header || "Article"} 
      </Text>
    )
  }

  const Title = () => {
    return (
      <Box 
        as="h3"
        margin={{ top: "0", bottom: "0.5em" }}
        height="62px"
        style={{ overflow: "hidden" }}
      >
        <AnchorLink 
          label={<Text weight={700} size="large" color="black">{title}</Text>} 
          path={path}
        />
      </Box>
    )
  }

  const Description = () => {
    return (
      <Box 
        as="p"
        flex="grow"
        margin="0"
        height="178px"
        style={{ overflow: "hidden" }}
      >
        <Paragraph 
          weight={300} 
          margin="0"
          size="medium"
          color="dark-2"
          style={{ lineHeight: "1.7em" }}
        >
          {description}
        </Paragraph>
      </Box>
    )
  }

  return (
    <Box
      as="article"
      round="xsmall"
      basis={basis}
      border={{
        size: "xsmall",
        color: "lightsteelblue"
      }}
      height={{
        min: productImage ? "100px" : "400px",
      }}
      margin={{
        ...{
          bottom: "1.5vw"
        },
        ...margin
      }}
      pad={pad && pad}
      style={{ overflow: "hidden" }}
    >
      <Box flex="grow">
        {
          featuredImage && (
            <Box margin={{ bottom: "medium" }}>
              <AnchorLink 
                label={
                  <Img 
                    fluid={featuredImage} 
                    alt={`Article image`}
                  />
                } 
                path={path}
              />
            </Box>   
          )
        }
        {
          productImage && (
            <Box height="55px" margin="medium" justify="center">
              <AnchorLink 
                label={
                  <img 
                    src={productImage.src} 
                    style={{ maxWidth: "75%", maxHeight: "55px" }}
                  />
                } 
                path={path}
              />
            </Box>   
          )
        }
        <Box pad={{ bottom: connector ? "xsmall" : "medium", left: "medium", right: "medium" }}>
          <Header />
          <Title />
          {
            description && <Description />
          }
          <AnchorLink 
            path={path}
            label="read more"
            margin={connector ? "0" : "36px 0 0"}
            size="small"
            reverse={true}
            icon={<Next size="small" />}
          />
        </Box>
      </Box>
    </Box>
  )
}

Article.defaultProps = {
  basis: "1/3",
  margin: {}
}

export default Article