const theme = {
  anchor: {
    textDecoration: "none",
    hover: {
      textDecoration: "none",
    },
    extend: {
      boxShadow: "none"
    }
  },
  global: {
    font: {
      family: "'Muli', sans-serif",
      size: '1em',
    },
    colors: {
      black: "#000000",
      brand: "#4C5FE8",
      "brand-1": "#3643C2",
      "brand-2": "#3E4CCE",
      "brand-3": "#4556db",
      "brand-4": "#5E6DEA",
      "brand-5": "#6E7CED",
      "brand-6": "#7F8AEF",
      "brand-dark": "#4859c4",
      focus: "compliment-1",
      "dark-1": "#181818",
      "dark-2": "#2f353f",
      "dark-3": "#3D3E3E",
      "dark-4": "#454d65",
      "dark-5": "#545050",
      "dark-6": "#6B6572",
      "light-0": "#F6F7FD",
      "light-1": "#F6F6F6",
      "light-2": "#EFF0F1",
      "light-3": "#E8E9EB",
      "light-4": "#D9DBDE",
      "light-5": "#D1D3D7",
      "light-6": "#CACCD1",
      "accent-1": "mediumvioletred",
      "accent-2": "crimson",
      "accent-3": "#3cab1a",
      "accent-4": "tomato",
      "accent-5": "slategray",
      "accent-light-1": "#EBDEFA",
      text: {
        dark: "#5A6270",
        light: "#959DAA",
      }
    }
  },
  focus: {
    border: {
      color: "transparent"
    }
  },
  rangeInput: {
    track: {
      height: "2px",
      color: "blue"
    }
  },
  heading: {
    font: {
      family: "'Muli', sans-serif",
    },
    weight: "600",
    level: {
      1: {
        font: {
          family: "'Barlow Semi Condensed', sans-serif",
          weight: "400",
        },
        large: {
          size: "60px",
          height: "84px",
          maxWidth: "1968px"
        }
      },
    },
  },
  layer: {
    overlay: {
      background: "rgba(0, 0, 0, 0.9)"
    }
  },
  button: {
    border: {
      radius: "4px",
      width: "1px",
    },
    primary: {
      color: {
        dark: "white",
        light: "brand"
      }
    },
    size: {
      small: {
        border: {
          radius: "4px"
        },
        pad: {
          vertical: "4px", 
          horizontal: "12px"
        }
      },
      medium: {
        border: {
          radius: "4px",
        }
      },
      large: {
        border: {
          radius: "4px",
        }
      }
    }
  }

}

export default theme