import React, { useEffect } from 'react'
import { Box, Grommet } from 'grommet'
import { useOptions } from '../../../state/hooks' 
import { theme } from '../../../style'
import { Logo, Tags, Hero, TalkWithExpert } from '../../../components'
import { DefaultLayout, Footer, Header, Main } from '../index'

const ConnectorLayout = ({ title, description, canonical, location, pageHero, tags, pageTheme, titleTemplate, footer, children }) => {
  const { setLocation } = useOptions() 
  const { pathname } = location

  useEffect(() => {
    setLocation(location)
  }, [location])

  return (
    <Grommet theme={theme} full>
      <DefaultLayout 
        location={location} 
        title={title} 
        titleTemplate={titleTemplate}
        description={description}
        canonical={canonical}
      >
        <Box style={{ minHeight: "100vh" }}>
          <Header 
            border={{
              color: "light-3",
              size: "xsmall",
              style: "solid",
              side: "bottom"
            }}
            pad={{
              vertical: "small",
              horizontal: "11vw",
            }}
            pageTheme={pageTheme}
          />
          <Main margin={{ top: "-12px" }}>
            <Box
              direction="row"
              align="center"
              border={{
                color: "light-3",
                size: "xsmall",
                style: "solid",
                side: "bottom"
              }}
              pad={{
                vertical: "small",
                horizontal: "11vw"
              }}
              background="#ffffffed"
              style={{
                position: "sticky",
                top: "0",
                zIndex: 1
              }}
            >
              <Box>
                <Logo 
                  showLogo={false}
                  companyName={null}
                  logoLabel="Type"
                />
              </Box>
              <Tags 
                tags={tags} 
                label="All Connectors"
                path="/connectors"
                pathname={pathname} 
              />
            </Box>
            {
              pageHero && <Hero {...pageHero} />
            }
            <Box
              align="center"
              margin={{ horizontal: "11vw" }}
            >
              {children}
            </Box>
          </Main>
          <TalkWithExpert 
            margin={{ top: "xlarge" }} 
            background="dark-4"
          />
          {
            footer && (
              <Footer 
                background="light-0" 
                margin="0" 
                pad={{ horizontal: "0", vertical: "large" }}
                align="center"
                style={{ textAlign: "center" }}
                {...footer} 
              />
            )
          }
        </Box>
      </DefaultLayout>
    </Grommet>
  )
}

ConnectorLayout.defaultProps = {
  header: {},
  footer: {},
  pageHero: {},
  tags: [],
  pageTheme: "dark",
  titleTemplate: "Hippobyte"
}

export default ConnectorLayout