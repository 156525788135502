import React from 'react'
import styled from 'styled-components'
import { Box } from 'grommet'

const MenuItemContainer = ({ label, children, ...rest}) => (
  <Container 
    pad={{ vertical: "xsmall", horizontal: "small" }}
    color="black"
    width="full"
    {...rest}
  >
    {label || children}
  </Container>
)

const Container = styled(Box)`
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: background .3s;
  font-weight: 500;
  font-size: 0.95em;
  letter-spacing: 0.6px;
  border-radius: 6px;

  &:hover {
    background: ${props => props.theme.global.colors[props.backgroundHover || "light-0"]};
  }
`

export default MenuItemContainer