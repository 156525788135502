import React from 'react'
import Img from 'gatsby-image'
import { Box, Heading, Text } from 'grommet'
import { FormNextLink } from 'grommet-icons'
import { AnchorLink, Icon } from '../..'

const BlogPostPreview = ({ author, date, title, description, featuredpost, featuredimage, slug, readMoreLink }) => {
  const featuredImageFluid = featuredimage && featuredimage.childImageSharp.fluid
  const { authorName, email, linkedin, twitter } = author.frontmatter

  return (
    <Box 
      border={{
        color: "#cce6ea",
        size: "xsmall",
        style: "solid",
        side: "bottom"
      }}
      pad={{ bottom: "large" }}
      margin={{ bottom: "large" }}
    >
      <AnchorLink path={slug}>
        <Heading 
          level="1"
          color="black"
          size="2.4em"
          margin={{ top: "0", bottom: "xsmall" }}
        >
          {title}
        </Heading>
      </AnchorLink>
      <Box
        margin={{
          bottom: "14px"
        }}
        direction="row"
      >
        <Box
          direction="row"
          justify="start"
          align="center"
          margin={{
            top: "small",
          }}
        >
          <AnchorLink href={linkedin}>
            <Text size="1em" weight="700" color="brand">{authorName}</Text>
          </AnchorLink>
          <AnchorLink href={linkedin}>
            <Icon type="LinkedIn" size="xsmall" style={{ marginLeft: "0.5em" }} />
          </AnchorLink>
        </Box>
      </Box>
      {
        featuredImageFluid && (
          <AnchorLink path={slug}>
            <Box
              margin={{ top: "xsmall", bottom: "medium" }}
            >
              <Img fluid={featuredImageFluid} alt={`Article image`} />
            </Box>
          </AnchorLink>
        )
      }
      {
        description && (
          <Text weight="500" color="dark-2" size="1.155em" style={{ lineHeight: "1.7em" }}>{description}</Text>
        )
      }
      {
        readMoreLink && (
          <AnchorLink 
            label="Read More" 
            path={slug} 
            reverse={true}
            icon={<FormNextLink />} 
            margin={{ top: "medium" }}
          />
        )
      }
    </Box>
  )
}

BlogPostPreview.defaultProps = {
  readMoreLink: true
}

export default BlogPostPreview