import React from 'react'
import { Box, Text } from 'grommet'
import { AnchorButton } from '../../../components'

const Section = ({ title, description, button, children, ...rest }) => {
  return (
    <Box 
      {...rest}
    >
      <Content
        title={title}
        description={description}
        button={button}
      />
      {children}
    </Box>
  )
}

const Content = ({ title, description, button }) => {
  return (
    <Box direction="row" align="center" fill="horizontal">
      {
        (title || description) && (
          <Box basis="85%">
            { title && <Title {...title} /> }
            { description && <Description {...description} /> }
          </Box>
        )
      }
      { 
        button && (
          <Box basis="15%" justify="end">
            <AnchorButton {...button} />
          </Box>
        )
      }
    </Box>
  )
}

const Title = ({ label, ...rest }) => {
  return (
    <Text
      color="white"
      size="xxlarge"
      weight={900}
      margin={{
        bottom: "small"
      }}
      {...rest}
    >
      {label}
    </Text>
  )
}

const Description = ({ label, ...rest }) => {
  return (
    <Text
      color="white"
      weight={300}
      {...rest}
    >
      {label}
    </Text>
  )
}

export default Section